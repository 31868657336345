import { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth } from '../AuthContext';

const useAuthenticatedApi = () => {
    const { user, getAccessToken, refreshToken } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const initialFetchMade = useRef(false);
    const isFetchingRef = useRef(false);

    const authFetch = useCallback(async (url, options = {}) => {
        let token = getAccessToken();

        if (!token) {
            token = await refreshToken();
            if (!token) {
                throw new Error('Unable to refresh token');
            }
        }

        const authOptions = {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };

        let response = await fetch(url, authOptions);

        if (response.status === 401) {
            // Token might be expired, try to refresh
            token = await refreshToken();
            if (token) {
                authOptions.headers['Authorization'] = `Bearer ${token}`;
                response = await fetch(url, authOptions);
            } else {
                throw new Error('Session expired. Please log in again.');
            }
        }

        if (!response.ok) {
            throw new Error('API request failed');
        }

        return response.json();
    }, [getAccessToken, refreshToken]);

    const executeRequest = useCallback(async (fetchFunction, dependencies = [], isInitialFetch = false) => {
        if (!user || !user.id || (isInitialFetch && (initialFetchMade.current || isFetchingRef.current))) {
            return null;
        }
        
        isFetchingRef.current = true;
        setIsLoading(true);
        setError(null);

        try {
            const result = await fetchFunction(user.id);
            if (isInitialFetch) {
                initialFetchMade.current = true;
            }
            return result;
        } catch (err) {
            console.error(`Error fetching data:`, err);
            setError(err.message);
            return null;
        } finally {
            setIsLoading(false);
            isFetchingRef.current = false;
        }
    }, [user]);

    const useApiRequest = (fetchFunction, dependencies = []) => {
        const [data, setData] = useState(null);

        useEffect(() => {
            if (user && !initialFetchMade.current) {
                executeRequest(fetchFunction, dependencies, true).then(result => {
                    if (result) setData(result);
                });
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [user, fetchFunction, ...dependencies]);

        const refetch = useCallback(() => {
            initialFetchMade.current = false;
            executeRequest(fetchFunction, dependencies, true).then(result => {
                if (result) setData(result);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [fetchFunction, ...dependencies]);

        return { data, isLoading, error, refetch };
    };

    return { authFetch, useApiRequest, executeRequest };
};

export default useAuthenticatedApi;
