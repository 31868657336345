import React, { useState } from 'react';
import { Clock, Zap, ChevronDown, ChevronUp } from 'lucide-react';

const dummyAlert = {
  alert_id: 638,
  completion_time: "2024-08-18T02:08:32.000000",
  date: "2024-08-18T02:08:32.000000",
  keywords: [
    {
      keyword_id: 5,
      keyword_name: "HS510C",
      matches: [
        {
          end: 294,
          match_id: 7,
          original_text: null,
          start: 288,
          text: "HS510C"
        }
      ],
      subreddit_name: "GunAccessoriesForSale"
    }
  ],
  message: "[WTS] Aero Upper, Holosun Combo, B5 Sopmod, Surefire Brake, Streamlight Protac HLX 1K \n\n Timestamp: [https://imgur.com/a/NhPZTeT](https://imgur.com/a/NhPZTeT) I gave up on a build, got too much going on. Aero Kodiak Brown 16inch Upper, brand new, never even put on a lower - $300 Holosun HS510C + HM3X, brand new with all orginal packaging - $360 Odin Works 9mm 5inch Barrel, bnib - $80 B5 Sopmod BMC, new and never mounted but dont have packaging - $90 White Label Armory Nitride BCG + Milspec CH, brand new, never fired - $85 Streamlight ProTac HLX 1000 Lumen WML with Tapeswitch (Picatinny mount, mlok offset mount, everything listed on the packaging included) with some salt but 100% functional - $95 Surefire Muzzle Brake with Rocksett and All Shims Unused (but the brake was mounted, just didnt use the rocksett or shims originally. Some salt) - $110 Bundle > Dibs > PM. Paypal FF, no notes MESSAGE ME FIRST. USERS ARE PRETENDING TO BE ME AND SCAMMING OTHERS. VERIFY ITS MY ACCOUNT BEFORE REPLYING TO ME. I WILL NOT MESSAGE YOU FIRST.",
  processing_time: 0.547,
  status: "processed",
  time_delta_seconds: 13.38
};

const gradientCombos = [
    { start: "from-purple-200", end: "to-pink-200" },
    { start: "from-blue-200", end: "to-indigo-200" },
    { start: "from-green-200", end: "to-teal-200" },
    { start: "from-red-200", end: "to-orange-200" },
    { start: "from-yellow-200", end: "to-green-200" },
    { start: "from-indigo-200", end: "to-purple-200" },
    { start: "from-pink-200", end: "to-rose-200" },
    { start: "from-cyan-200", end: "to-blue-200" },
    { start: "from-fuchsia-200", end: "to-pink-200" },
    { start: "from-amber-200", end: "to-orange-200" },
    { start: "from-lime-200", end: "to-emerald-200" },
    { start: "from-sky-200", end: "to-blue-200" },
  ];
      
const getRandomGradient = () => {
  const randomIndex = Math.floor(Math.random() * gradientCombos.length);
  return gradientCombos[randomIndex];
};

const AlertCard = ({ 
  alert = dummyAlert, 
  gradientStart = "from-purple-500", 
  gradientEnd = "to-pink-500",
  maxChars = 300
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [gradient] = useState(getRandomGradient());  // Use useState to keep the gradient consistent on re-renders
  const preserveWhitespaceClass = "whitespace-pre-wrap";

  const calculateTimeDelta = (dateString) => {
    const now = new Date();
    const alertDate = new Date(dateString);
    const diffInSeconds = Math.floor((now - alertDate) / 1000);
    
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  };

  const highlightMatches = (text, matches) => {
    if (!text || !matches || matches.length === 0) return text;
    let result = [];
    let lastIndex = 0;
    matches.forEach((match, index) => {
      result.push(text.slice(lastIndex, match.start));
      result.push(
        <span key={index} className="bg-yellow-200 font-bold">
          {text.slice(match.start, match.end)}
        </span>
      );
      lastIndex = match.end;
    });
    result.push(text.slice(lastIndex));
    return result;
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const keyword = alert.keywords && alert.keywords[0];
  const subredditName = keyword ? keyword.subreddit_name : 'Unknown';
  const truncatedSubredditName = truncateText(subredditName, 25);
  const message = alert.message || 'No message available';
  const matches = keyword && keyword.matches ? keyword.matches : [];

  const displayedMessage = isExpanded ? message : truncateText(message, maxChars);

  // Get unique keyword names
  const keywordNames = [...new Set(alert.keywords.map(k => k.keyword_name))];

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 p-4">
      <div className="w-full max-w-3xl bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200 transform hover:scale-105 transition-transform duration-300">
        <div className={`bg-gradient-to-r ${gradient.start} ${gradient.end} p-6 text-white`}>
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold">/r/{truncatedSubredditName}</h2>
            <div className="bg-white text-gray-800 text-xs font-semibold px-2 py-1 rounded-md shadow">
              ID: {alert.alert_id}
            </div>
          </div>
        </div>
        <div className="p-8">
          <p className={`text-gray-800 text-lg mb-4 ${preserveWhitespaceClass}`}>
            {highlightMatches(displayedMessage, matches)}
          </p>
          {message.length > maxChars && (
            <button 
              onClick={() => setIsExpanded(!isExpanded)} 
              className="text-blue-500 hover:text-blue-700 flex items-center mb-4"
            >
              {isExpanded ? (
                <>
                  <ChevronUp className="w-4 h-4 mr-1" />
                  Show Less
                </>
              ) : (
                <>
                  <ChevronDown className="w-4 h-4 mr-1" />
                  Show More
                </>
              )}
            </button>
          )}
          <div className="flex justify-between items-center text-sm text-gray-600">
            <div className="flex items-center">
              <Clock className="w-5 h-5 mr-2 text-blue-500" />
              <span>{calculateTimeDelta(alert.date)}</span>
            </div>
            <div className="flex items-center">
              <Zap className="w-5 h-5 mr-2 text-green-500" />
              <span>Processed in {alert.processing_time.toFixed(3)}ms</span>
            </div>
          </div>
          <div className="mt-4 pt-4 border-t border-gray-200 text-sm text-gray-500 flex justify-between items-center">
            <span>Time to detect: {alert.time_delta_seconds.toFixed(2)}s</span>
            <div className="flex flex-wrap justify-end">
              {keywordNames.map((name, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 mb-2">
                  {name}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertCard;
		