import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle, Medal, ArrowUpCircle, ArrowDownCircle } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../AuthContext';
import { usePaddle } from '../../PaddleContext';
import useAuthenticatedApi from '../../hooks/useAuthenticatedApi';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footers';
import Modal from '../../components/Modal';
import Notification from '../../components/Notification';

const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

const getCachedCountryCode = () => {
  const cachedData = localStorage.getItem('countryCodeData');
  if (!cachedData) return null;

  const { countryCode, timestamp } = JSON.parse(cachedData);
  const now = new Date().getTime();

  if (now - timestamp < EXPIRATION_TIME) {
    return countryCode;
  }

  return null;
};

const cacheCountryCode = (countryCode) => {
  const data = {
    countryCode,
    timestamp: new Date().getTime()
  };
  localStorage.setItem('countryCodeData', JSON.stringify(data));
};

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [countryCode, setCountryCode] = useState(getCachedCountryCode());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { paddle, prices } = usePaddle();
  const [currentPlanCycle, setCurrentPlanCycle] = useState(null);

  const [currentPlan, setCurrentPlan] = useState(null);
  const [isUpgradeDowngrade, setIsUpgradeDowngrade] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('success');

  const { authFetch, executeRequest } = useAuthenticatedApi();

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCountryCode = async () => {
      if (!countryCode) {
        try {
          const response = await fetch('https://ipapi.co/json');
          const data = await response.json();
          setCountryCode(data.country);
          cacheCountryCode(data.country);
        } catch (error) {
          console.error('Error fetching country code:', error);
        }
      }
    };

    fetchCountryCode();
  }, [countryCode]);

  const handleCheckout = useCallback((title, cycle) => {
    if (title === "Free") {
      navigate('/keywords');
      return;
    }

    if (!user) {
      const returnUrl = `/pricing?plan=${title}&cycle=${cycle}`;
      navigate(`/login?redirect=${encodeURIComponent(returnUrl)}`);
      return;
    }

    const priceId = getPriceId(title, cycle);

    if (paddle && priceId) {
      const currentUrl = window.location.origin;
      const successUrl = `${currentUrl}/success`;
      paddle.Checkout.open({
        settings: {
          displayMode: 'overlay',
          theme: 'light',
          successUrl: successUrl
        },
        customData: {
          "user_id": user.id,
        },
        items: [
          {
            priceId: priceId,
            quantity: 1
          }
        ],
        customer: {
          email: user.email,
          address: {
            countryCode: countryCode
          }
        }
      });
    }
  }, [user, navigate, paddle, countryCode]);

  useEffect(() => {
    if (user && paddle) {
      const searchParams = new URLSearchParams(location.search);
      const plan = searchParams.get('plan');
      const cycle = searchParams.get('cycle');
      const upgradeDowngrade = searchParams.get('upgradedowngrade');
      
      if (plan && cycle) {
        setCurrentPlan(plan);
        setCurrentPlanCycle(cycle);
        console.log(cycle)
        if (upgradeDowngrade === 'true') {
          setIsUpgradeDowngrade(true);
          setBillingCycle(cycle); // Set the initial billing cycle to match the current plan
        } else {
          handleCheckout(plan, cycle);
        }
      }
    }
  }, [user, paddle, location.search, handleCheckout]);

  const handleUpgradeDowngrade = async (action, fromPlan, toPlan, toCycle) => {
    try {
      const response = await executeRequest(async () => {
        return await authFetch('/api/update-subscription', {
          method: 'POST',
          body: JSON.stringify({
            userId: user.id,
            toPlan,
            toCycle,
          }),
        });
      });

      const data = await response.json();

      if (response.ok) {
        setIsModalOpen(false);
        setCurrentPlan(data.newPlan);
        setCurrentPlanCycle(data.newBillingFrequency);

        setNotificationMessage(`You're all set! Successfully ${action}d to ${data.newPlan} plan`);
        setNotificationType('success');
        setShowNotification(true);
      } else {
        throw new Error(data.error || 'Failed to process plan change');
      }

    } catch (error) {
      console.error('Error processing upgrade/downgrade:', error);

      setIsModalOpen(false);

      setNotificationMessage(error.message || 'Failed to process plan change. Please try again.');
      setNotificationType('error');
      setShowNotification(true);
    }
  };

  const openUpgradeDowngradeModal = (action, fromPlan, toPlan, toCycle) => {
    const fromFrequency = currentPlanCycle;
    let toFrequency = toCycle;

    if (action === 'upgrade' && fromPlan === toPlan && fromFrequency === 'monthly' && toFrequency === 'yearly') {
      // This is correct, keep it as is
    } else if (fromPlan === toPlan && fromFrequency === toFrequency) {
      console.error('Invalid upgrade/downgrade: same plan and cycle');
      return;
    }

    const content = (
      <div>
        <h2 className="text-2xl font-bold mb-4">
          {action === 'upgrade' ? 'Upgrade' : 'Downgrade'} Confirmation
        </h2>
        <p className="mb-4">
          {action === 'upgrade'
            ? `Are you sure you want to upgrade from ${fromPlan} ${fromFrequency} to ${toPlan} ${toFrequency}? You will be prorated and billed immediately.`
            : `Are you sure you want to downgrade from ${fromPlan} ${fromFrequency} to ${toPlan} ${toFrequency}? Your current subscription will continue until the next billing cycle, then you will be downgraded.`}
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setIsModalOpen(false)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={() => handleUpgradeDowngrade(action, fromPlan, toPlan, toFrequency)}
            className={`px-4 py-2 ${
              action === 'upgrade' ? 'bg-green-500 hover:bg-green-600' : 'bg-amber-500 hover:bg-amber-600'
            } text-white rounded`}
          >
            Confirm
          </button>
        </div>
      </div>
    );

    setModalContent(content);
    setIsModalOpen(true);
  };

  const getPriceId = (title, cycle) => {
    if (title === "Silver") {
      return cycle === 'monthly' ? 'pri_01j3xxyrtpqxbcpbfx15fabyhv' : 'pri_01j3xxzejbbkqtfgmhtbrzj8bj';
    } else if (title === "Gold") {
      return cycle === 'monthly' ? 'pri_01j3xy0qrktsw02cv46h5bftg7' : 'pri_01j3xy16ktwxse0yfytcky0tcq';
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>Pricing - Notifyr</title>
        <meta name="description" content="Choose the perfect Notifyr plan for your needs. From free to premium options, find the right package to monitor Reddit posts and comments." />
        <meta name="keywords" content="pricing, plans, Notifyr, subscription, free plan, premium plan, Reddit alerts" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-amber-100 to-amber-50">
        <Navbar />
        <div className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl font-extrabold text-amber-900 text-center mb-12">Simple, Transparent Pricing</h2>
            <p className="text-xl text-amber-700 text-center mb-12">Choose the Notifyr plan that best fits your Reddit monitoring needs. No hidden fees, cancel anytime.</p>
            
            <div className="flex justify-center mb-12">
              <div className="bg-amber-200 p-1 rounded-full">
                <button
                  className={`px-4 py-2 rounded-full ${
                    billingCycle === 'monthly' ? 'bg-white shadow-sm text-amber-900' : 'text-amber-700'
                  }`}
                  onClick={() => setBillingCycle('monthly')}
                >
                  Monthly
                </button>
                <button
                  className={`px-4 py-2 rounded-full ${
                    billingCycle === 'yearly' ? 'bg-white shadow-sm text-amber-900' : 'text-amber-700'
                  }`}
                  onClick={() => setBillingCycle('yearly')}
                >
                  Yearly (Save 20%)
                </button>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
              <PricingCard 
                title="Free" 
                price="$0" 
                features={['1 Keyword', '1 Subreddit', 'Email Alerts', 'Community Support']}
                icon={<BronzeMedal />}
                handleCheckout={handleCheckout}
                billingCycle={billingCycle}
                currentPlan={currentPlan}
                currentPlanCycle={currentPlanCycle}
                isUpgradeDowngrade={isUpgradeDowngrade}
                openUpgradeDowngradeModal={openUpgradeDowngradeModal}
              />
              <PricingCard 
                title="Silver" 
                price={prices.silver ? (billingCycle === 'monthly' ? prices.silver.month : prices.silver.year) : "Loading..."}
                features={['5 Keywords', '3 Subreddits', 'Email Alerts', '24/7 Support']}
                icon={<ShimmeringSilverMedal />}
                billingCycle={billingCycle}
                handleCheckout={handleCheckout}
                currentPlan={currentPlan}
                currentPlanCycle={currentPlanCycle}
                isUpgradeDowngrade={isUpgradeDowngrade}
                openUpgradeDowngradeModal={openUpgradeDowngradeModal}
              />
              <PricingCard 
                title="Gold" 
                price={prices.gold ? (billingCycle === 'monthly' ? prices.gold.month : prices.gold.year) : "Loading..."}
                features={['25 Keywords', '10 Subreddits', 'Email & SMS Alerts', 'API Access', '24/7 Priority Support']}
                highlighted={true}
                icon={<GoldMedal />}
                billingCycle={billingCycle}
                handleCheckout={handleCheckout}
                currentPlan={currentPlan}
                currentPlanCycle={currentPlanCycle}
                isUpgradeDowngrade={isUpgradeDowngrade}
                openUpgradeDowngradeModal={openUpgradeDowngradeModal}
              />
            </div>
          </div>
        </div>
        <FAQ />
        <Footer />
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {modalContent}
        </Modal>
        {showNotification && (
          <Notification
            message={notificationMessage}
            type={notificationType}
            onClose={handleCloseNotification}
          />
        )}
      </div>
    </>
  );
};

const PricingCard = ({ 
  title, 
  price, 
  features, 
  highlighted = false, 
  icon, 
  billingCycle, 
  handleCheckout, 
  currentPlan,
  currentPlanCycle,
  isUpgradeDowngrade,
  openUpgradeDowngradeModal
}) => {
  const isCurrentPlan = currentPlan === title;
  const isCurrentCycle = currentPlanCycle === billingCycle;

  const canUpgrade = 
    (currentPlan === "Free") || 
    (currentPlan === "Silver" && title === "Gold") ||
    (isCurrentPlan && currentPlanCycle === "monthly" && billingCycle === "yearly");
  
  const canDowngrade = 
    (currentPlan === "Silver" && title === "Free") || 
    (currentPlan === "Gold" && (title === "Silver" || title === "Free")) ||
    (isCurrentPlan && currentPlanCycle === "yearly" && billingCycle === "monthly");

  const isGreyedOut = isCurrentPlan && isCurrentCycle;

  const parsedPrice = price ? parseFloat(price.replace('$', '')) : 0;
  const yearlyPrice = billingCycle === 'yearly' ? parsedPrice : parsedPrice * 12;
  const monthlyPrice = billingCycle === 'yearly' ? (parsedPrice / 12).toFixed(2) : parsedPrice.toFixed(2);

  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className={`rounded-lg shadow-lg overflow-hidden flex flex-col ${highlighted ? 'border-2 border-amber-500' : ''}`}
      layout // This enables layout animations
    >
      <motion.div 
        className={`px-6 py-8 ${highlighted ? 'bg-amber-100' : 'bg-amber-50'}`}
        layout // This enables layout animations for this div
      >
        <h3 className="text-2xl font-semibold text-amber-900 text-center flex items-center justify-center">
          {icon && <span className="mr-2">{icon}</span>}
          {title}
        </h3>
        <AnimatePresence mode="wait">
          <motion.div
            key={billingCycle}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="mt-4 text-center"
          >
            <span className="text-5xl font-extrabold text-amber-900">${monthlyPrice}</span>
            <span className="text-xl text-amber-700">/mo</span>
            {billingCycle === 'yearly' && (
              <p className="mt-2 text-sm text-amber-700">
                ${yearlyPrice} billed yearly
              </p>
            )}
          </motion.div>
        </AnimatePresence>
      </motion.div>
      <motion.div 
        className="px-6 pt-6 pb-8 bg-white flex-grow flex flex-col justify-between"
        layout // This enables layout animations for this div
      >
        <ul className="space-y-4 mb-8">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <CheckCircle className="flex-shrink-0 w-6 h-6 text-amber-500" />
              <span className="ml-3 text-amber-700">{feature}</span>
            </li>
          ))}
        </ul>
        <motion.div 
          className="mt-auto"
          layout // This enables layout animations for this div
        >
          {isUpgradeDowngrade ? (
            <div className="space-y-2">
              {isGreyedOut ? (
                <motion.button
                  className="block w-full px-4 py-2 rounded-md text-center font-medium bg-gray-300 text-gray-600 cursor-not-allowed"
                  disabled
                  layout // This enables layout animations for this button
                  whileHover={{ scale: 1 }} // Disable hover effect for greyed out button
                >
                  Current Plan
                </motion.button>
              ) : (
                <>
                  {canUpgrade && (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => openUpgradeDowngradeModal('upgrade', currentPlan, title, billingCycle)}
                      className="block w-full px-4 py-2 rounded-md text-center font-medium bg-green-500 text-white hover:bg-green-600 transition duration-300 ease-in-out"
                      layout // This enables layout animations for this button
                    >
                      <ArrowUpCircle className="inline-block mr-2" />
                      {isCurrentPlan && billingCycle === "yearly" ? 'Switch to Yearly' : 'Upgrade'}
                    </motion.button>
                  )}
                  {canDowngrade && (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => openUpgradeDowngradeModal('downgrade', currentPlan, title, billingCycle)}
                      className="block w-full px-4 py-2 rounded-md text-center font-medium bg-red-500 text-white hover:bg-red-600 transition duration-300 ease-in-out"
                      layout // This enables layout animations for this button
                    >
                      <ArrowDownCircle className="inline-block mr-2" />
                      Downgrade
                    </motion.button>
                  )}
                  {!canUpgrade && !canDowngrade && (
                    <motion.button
                      className="block w-full px-4 py-2 rounded-md text-center font-medium bg-gray-300 text-gray-600 cursor-not-allowed"
                      disabled
                      layout // This enables layout animations for this button
                      whileHover={{ scale: 1 }} // Disable hover effect for unavailable button
                    >
                      Not Available
                    </motion.button>
                  )}
                </>
              )}
            </div>
          ) : (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleCheckout(title, billingCycle)}
              className={`block w-full px-4 py-2 rounded-md text-center font-medium ${
                highlighted ? 'bg-amber-500 text-white hover:bg-amber-600' : 'bg-amber-200 text-amber-700 hover:bg-amber-300'
              } transition duration-300 ease-in-out`}
              layout // This enables layout animations for this button
            >
              {title === "Free" ? "Get Started" : "Choose Plan"}
            </motion.button>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

  
  const BronzeMedal = () => (
    <motion.div
      animate={{
        opacity: [1, 0.5, 1],
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    >
      <Medal className="w-6 h-6 text-amber-600" />
    </motion.div>
  );
  
  const ShimmeringSilverMedal = () => (
    <motion.div
      animate={{
        opacity: [1, 0.8, 1],
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    >
      <Medal className="w-6 h-6 text-amber-400" />
    </motion.div>
  );
  
  const GoldMedal = () => (
    <motion.div
      animate={{
        opacity: [1, 0.8, 1],
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    >
      <Medal className="w-6 h-6 text-amber-300" />
    </motion.div>
  );
  
  const FAQ = () => {
    const faqs = [
      {
        question: "What's included in the free plan?",
        answer: "The free plan includes 1 keyword, 1 subreddit, email alerts, and access to our community support."
      },
      {
        question: "Can I upgrade or downgrade my plan at any time?",
        answer: "Yes, you can upgrade or downgrade your plan at any time. When upgrading, you'll be prorated and billed immediately. When downgrading, your current subscription will continue until the next billing cycle, then you'll be moved to the new plan."
      },
      {
        question: "Do you offer a trial period for paid plans?",
        answer: "We offer a 14-day free trial for all our paid plans. You can cancel anytime during this period without being charged."
      },
      {
        question: "What payment methods do you accept?",
        answer: "We accept all major credit cards, PayPal, and bank transfers for annual plans."
      },
      {
        question: "How does the billing work when I upgrade or downgrade?",
        answer: "When you upgrade, we'll calculate the prorated amount for the remainder of your current billing cycle and charge you immediately. For downgrades, we'll allow you to continue using your current plan until the end of the billing cycle, then switch you to the new plan."
      },
    ];
  
    return (
      <section className="bg-white py-16">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-center text-amber-900 mb-8">Frequently Asked Questions</h2>
          <dl className="space-y-8">
            {faqs.map((faq, index) => (
              <div key={index}>
                <dt className="text-lg font-semibold text-amber-900 mb-2">{faq.question}</dt>
                <dd className="text-amber-700">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </section>
    );
  };
  
  export default PricingPage;