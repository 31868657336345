import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Clock, Filter, Bell, ShoppingCart, X, AlertTriangle } from 'lucide-react';

const FeatureComparisonSection = () => {
  return (
    <div className="relative py-24 lg:py-32 bg-amber-50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.h2 
          className="text-4xl sm:text-5xl font-extrabold text-amber-900 text-center mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Be First, Get the Deal
        </motion.h2>
        <motion.p 
          className="text-xl text-amber-800 text-center mb-16 max-w-3xl mx-auto"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          In the fast-paced world of Reddit deals, seconds matter. Our app ensures you're always first in line, never missing out on the hottest offers.
        </motion.p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
          <FeatureCard2
            title="With Our Lightning-Fast Alerts"
            features={[
              { icon: <Zap className="w-6 h-6" />, text: "Be the first to comment and claim deals" },
              { icon: <Clock className="w-6 h-6" />, text: "Receive notifications within seconds of posting" },
              { icon: <Filter className="w-6 h-6" />, text: "Customize alerts for specific items you're after" },
              { icon: <Bell className="w-6 h-6" />, text: "Get instant notifications on your preferred device" },
              { icon: <ShoppingCart className="w-6 h-6" />, text: "Score amazing deals before they're gone" },
            ]}
            isGood={true}
          />
          <FeatureCard2
            title="Without Our App"
            features={[
              { icon: <X className="w-6 h-6" />, text: "Miss out as others claim deals before you" },
              { icon: <AlertTriangle className="w-6 h-6" />, text: "Waste time constantly refreshing Reddit" },
              { icon: <X className="w-6 h-6" />, text: "Lose opportunities due to delayed notifications" },
              { icon: <X className="w-6 h-6" />, text: "Struggle to track multiple subreddits for deals" },
              { icon: <Clock className="w-6 h-6" />, text: "Arrive too late, finding only 'already sold' comments" },
            ]}
            isGood={false}
          />
        </div>
      </div>
    </div>
  );
};

const FeatureCard2 = ({ title, features, isGood }) => {
  const cardStyle = isGood
    ? "bg-amber-100 border-t-4 border-amber-500 shadow-lg"
    : "bg-amber-50 border border-amber-200 shadow-md";

  const iconStyle = isGood ? "text-amber-600" : "text-amber-400";
  const textStyle = isGood ? "text-amber-900" : "text-amber-700";

  return (
    <motion.div 
      className={`rounded-lg overflow-hidden ${cardStyle}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={`px-6 py-8 ${isGood ? 'bg-amber-200' : 'bg-amber-100'}`}>
        <h3 className={`text-2xl font-semibold ${isGood ? 'text-amber-900' : 'text-amber-800'}`}>{title}</h3>
      </div>
      <ul className="px-6 py-8 space-y-6">
        {features.map((feature, index) => (
          <motion.li 
            key={index} 
            className="flex items-start"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <span className={`flex-shrink-0 mr-3 ${iconStyle}`}>{feature.icon}</span>
            <span className={`text-lg ${textStyle}`}>{feature.text}</span>
          </motion.li>
        ))}
      </ul>
    </motion.div>
  );
};

export default FeatureComparisonSection;