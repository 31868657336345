import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import { useAuth } from '../../AuthContext';
import useAuthenticatedApi from '../../hooks/useAuthenticatedApi';
import AlertCard from './AlertCard';
import { useTheme } from '../../components/ThemeProvider';
import Navbar from '../../Navbar';
// Import icons from Lucide React
import { AlertCircle, Info, ChevronDown } from 'lucide-react';

const ShimmerCard = React.memo(() => {
  return (
    <div className="w-full h-64 bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200 mb-6 animate-pulse">
      <div className="h-20 bg-gray-300"></div>
      <div className="p-4">
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-2/3"></div>
      </div>
    </div>
  );
});

const AppPage = React.memo(() => {
  // const { user } = useAuth();
  const { authFetch, executeRequest } = useAuthenticatedApi();
  const { isDarkMode } = useTheme();
  const [page, setPage] = useState(1);
  const [allAlerts, setAllAlerts] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState('All');
  const [sortOrder, setSortOrder] = useState('newest');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const fetchAlerts = useCallback(async (userId, pageNum) => {
    console.log('Fetching alerts for page:', pageNum);
    const response = await authFetch(`/api/alerts?user_id=${userId}&page=${pageNum}`);
    console.log('API response:', response);
    return response;
  }, [authFetch]);

  const loadAlerts = useCallback(async (pageNum, isInitialFetch = false) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await executeRequest((userId) => fetchAlerts(userId, pageNum), [pageNum], isInitialFetch);
      if (result && result.alerts) {
        setAllAlerts(prevAlerts => [...prevAlerts, ...result.alerts]);
        setTotalPages(result.total_pages);
      }
    } catch (err) {
      console.error('Error fetching alerts:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [executeRequest, fetchAlerts]);

  useEffect(() => {
    loadAlerts(1, true);
  }, [loadAlerts]);

  const loadMoreAlerts = useCallback(() => {
    console.log('Load More clicked. Current page:', page);
    if (page < totalPages && !isLoading) {
      const nextPage = page + 1;
      setPage(nextPage);
      console.log('Incrementing page to:', nextPage);
      loadAlerts(nextPage);
    }
  }, [page, totalPages, isLoading, loadAlerts]);

  const keywordSet = useMemo(() => {
    if (!allAlerts || allAlerts.length === 0) return new Set(['All']);
    const keywords = allAlerts.flatMap(alert => 
      alert.keywords.map(keyword => keyword.keyword_name)
    );
    return new Set(['All', ...keywords]);
  }, [allAlerts]);

  const filteredAndSortedAlerts = useMemo(() => {
    let filtered = [...allAlerts];
    if (selectedKeyword !== 'All') {
      filtered = filtered.filter(alert => 
        alert.keywords.some(keyword => keyword.keyword_name === selectedKeyword)
      );
    }
    return filtered.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
    });
  }, [allAlerts, selectedKeyword, sortOrder]);

  const renderAlerts = useMemo(() => {
    if (isLoading && allAlerts.length === 0) {
      return Array(4).fill().map((_, index) => (
        <div key={index} className="w-full md:w-1/2 p-2">
          <ShimmerCard />
        </div>
      ));
    }
    return filteredAndSortedAlerts.map((alert) => (
      <div key={`alert-${alert.alert_id}`} className="w-full md:w-1/2 p-2">
        <AlertCard alert={alert} />
      </div>
    ));
  }, [isLoading, filteredAndSortedAlerts, allAlerts.length]);

  const renderContent = useMemo(() => {
    if (error) {
      return (
        <div className="flex flex-col items-center justify-center h-64">
          <AlertCircle className="w-16 h-16 text-red-500 mb-4" />
          <h2 className="text-2xl font-bold mb-2">Error Connecting to Server</h2>
          <p>Please try again later or contact support if the problem persists.</p>
        </div>
      );
    }

    if (!isLoading && filteredAndSortedAlerts.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-64">
          <Info className="w-16 h-16 text-blue-500 mb-4" />
          <h2 className="text-2xl font-bold mb-2">No Alerts Found</h2>
          <p>Want to add keywords or double-check your existing ones?</p>
          <a href="/keywords" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300">
            Manage Keywords
          </a>
        </div>
      );
    }

    return (
      <div className="flex flex-wrap -mx-2">
        {renderAlerts}
      </div>
    );
  }, [error, isLoading, filteredAndSortedAlerts, renderAlerts]);

  const selectClass = `appearance-none bg-transparent border-none w-full py-2 pl-3 pr-8 cursor-pointer focus:outline-none text-sm font-semibold`;
  const selectWrapperClass = `relative inline-block bg-white text-gray-800 border border-gray-200 rounded-md shadow-sm hover:shadow-md transition-shadow duration-300`;

  return (
    <>
      <Helmet>
        <title>Notifyr - Your Alerts</title>
        <meta name="description" content="View and manage your Reddit alerts with Notifyr." />
      </Helmet>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
        <Navbar />

        <main className="container mx-auto px-4 py-8">
          <h1 className={`text-4xl font-bold mb-8 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Alerts</h1>
          
          <div className="flex justify-between items-center mb-6 bg-white shadow-xl rounded-lg p-4 border border-gray-200">
            <div className="flex items-center space-x-4">
              <div className={selectWrapperClass}>
                <select 
                  value={selectedKeyword} 
                  onChange={(e) => setSelectedKeyword(e.target.value)}
                  className={selectClass}
                >
                  {Array.from(keywordSet).map(keyword => (
                    <option key={keyword} value={keyword}>{keyword}</option>
                  ))}
                </select>
                <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500" size={16} />
              </div>
              <div className={selectWrapperClass}>
                <select 
                  value={sortOrder} 
                  onChange={(e) => setSortOrder(e.target.value)}
                  className={selectClass}
                >
                  <option value="newest">Newest First</option>
                  <option value="oldest">Oldest First</option>
                </select>
                <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500" size={16} />
              </div>
            </div>
            <div className="text-sm text-gray-500">
              Showing {filteredAndSortedAlerts.length} alerts
            </div>
          </div>

          {renderContent}
          {isLoading && <p className="text-center mt-4">Loading more alerts...</p>}
          {!isLoading && page < totalPages && (
            <div className="flex justify-center mt-4">
              <button 
                onClick={loadMoreAlerts}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 shadow-md hover:shadow-lg"
              >
                Load More (Page {page + 1})
              </button>
            </div>
          )}
        </main>
      </div>
    </>
  );
});

export default AppPage;
