import React from 'react';

const ShimmerCard = () => {
  return (
    <div className="w-full bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200 mb-6 animate-pulse">
      <div className="bg-gray-200 h-16 p-4">
        <div className="bg-gray-300 w-1/4 h-8 rounded"></div>
      </div>
      <div className="p-4">
        <div className="space-y-3">
          <div className="bg-gray-200 h-4 rounded w-3/4"></div>
          <div className="bg-gray-200 h-4 rounded w-1/2"></div>
          <div className="bg-gray-200 h-4 rounded w-5/6"></div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerCard;