import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footers';

const RefundPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Refund Policy - Notifyr</title>
        <meta name="description" content="Learn about Notifyr's refund policy for our Reddit keyword alert service. We offer a 14-day refund policy to ensure your satisfaction." />
        <meta name="keywords" content="refund policy, Notifyr, Reddit alerts, customer satisfaction, 14-day refund" />
      </Helmet>
      <div className="min-h-screen bg-gray-100">
        <Navbar />
        <div className="h-24"></div>
        <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">Refund Policy</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">14-Day Refund Policy</h2>
            <p className="text-gray-700">We offer a 14-day refund policy for our Reddit Keyword Alerts service. If you are not satisfied with our service, you can request a full refund within 14 days of your initial purchase or renewal date.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">How to Request a Refund</h2>
            <p className="text-gray-700">To request a refund, please contact our customer support team at support@redditkeywordalerts.com within the 14-day period. Please provide your account email address and the reason for your refund request.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Refund Process</h2>
            <p className="text-gray-700">Once we receive your refund request, we will review it and process the refund within 5-10 business days. The refund will be issued to the original payment method used for the purchase.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Exceptions</h2>
            <p className="text-gray-700">Please note that we may deny refund requests if we find evidence of fraud, abuse of our refund policy, or excessive use of the service during the refund period.</p>
          </section>

          <p className="text-gray-700 mt-8">
            If you have any questions about our refund policy, please contact us at support@redditkeywordalerts.com.
          </p>

          <p className="text-gray-700 mt-4">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="mt-8">
            <Link to="/terms-of-service" className="text-blue-600 hover:text-blue-800 mr-4">View our Terms of Service</Link>
            <Link to="/privacy-policy" className="text-blue-600 hover:text-blue-800">View our Privacy Policy</Link>
          </div>
        </div>

        <Footer/>
      </div>
    </>
  );
};

export default RefundPolicyPage;
