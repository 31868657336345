import React from 'react';
import { motion } from 'framer-motion';
import { Users, Bell, Zap, Star } from 'lucide-react';
import { Typewriter } from 'react-simple-typewriter';
import Sonar from './Sonar';

const taglines = [
  "Monitor Reddit in Real-Time",
  "Never Miss a Relevant Post",
  "Stay Ahead of the Conversation"
];

const Hero = () => {
  return (
    <div className="relative bg-gradient-to-b from-blue-100 to-purple-100">
      <div className="h-16 lg:h-20"></div>
      <div className="min-h-[calc(100vh-4rem)] lg:min-h-[calc(100vh-5rem)] flex flex-col">
        <div className="flex-grow flex items-center relative overflow-hidden py-12 lg:py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10 w-full">
            <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-8 lg:space-y-0 lg:space-x-8">
              <div className="w-full lg:w-1/2 lg:pr-8 text-center lg:text-left">
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <motion.div 
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="inline-block mb-4 px-4 py-2 rounded-full bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg text-blue-600 text-sm font-semibold border border-white border-opacity-30"
                    style={{
                      boxShadow: `
                        0 4px 6px rgba(0, 0, 0, 0.1), 
                        0 1px 3px rgba(0, 0, 0, 0.08), 
                        inset 0 0 0 1px rgba(255, 255, 255, 0.1),
                        0 0 15px rgba(59, 130, 246, 0.3)
                      `,
                    }}
                  >
                    <span className="mr-2">🚀</span>
                    <span>New: Real-time Reddit Monitoring</span>
                  </motion.div>

                  <h1 className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-extrabold tracking-tight mb-6 bg-gradient-to-r from-blue-600 to-purple-600 text-transparent bg-clip-text">
                    <Typewriter
                      words={taglines}
                      loop={0}
                      cursor
                      cursorStyle='|'
                      typeSpeed={70}
                      deleteSpeed={50}
                      delaySpeed={3000}
                    />
                  </h1>
                  <p className="mt-4 lg:mt-6 max-w-2xl text-lg lg:text-xl text-gray-700 mb-6 lg:mb-8">
                    Get instant, customizable alerts for your keywords across any subreddit. Stay ahead in fast-moving Reddit communities.
                  </p>
                  <div className="flex flex-col sm:flex-row justify-center lg:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
                    <a href="/" className="w-full sm:w-auto px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-md text-center">
                      Start Monitoring
                    </a>
                    <a href="/" className="w-full sm:w-auto px-6 py-3 border border-blue-600 text-base bg-opacity-20 backdrop-filter backdrop-blur-sm font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 transition duration-300 ease-in-out transform hover:scale-105 shadow-md text-center">
                      Explore Features
                    </a>
                  </div>
                </motion.div>
              </div>
              <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  className="bg-white rounded-lg shadow-xl overflow-hidden"
                >
                  <div className="h-64 sm:h-80 lg:h-96">
                    <Sonar />
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white py-6 lg:py-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">
              <Statistic icon={<Users className="w-5 h-5 text-blue-500" />} value="10,000+" label="Active Users" />
              <Statistic icon={<Bell className="w-5 h-5 text-green-500" />} value="1M+" label="Alerts Sent" />
              <Statistic icon={<Zap className="w-5 h-5 text-yellow-500" />} value="<10ms" label="Avg. Processing Time" />
              <Statistic icon={<Star className="w-5 h-5 text-purple-500" />} value="99.9%" label="Uptime" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Statistic = ({ icon, value, label }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="text-center"
  >
    <div className="flex justify-center">{icon}</div>
    <p className="mt-1 text-base sm:text-lg font-bold text-gray-900">{value}</p>
    <p className="mt-0.5 text-xs sm:text-sm text-gray-500">{label}</p>
  </motion.div>
);

export default Hero;