import React from 'react';
import { motion } from 'framer-motion';
import { Bell, Inbox, Zap, Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footers';

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About Notifyr - Your Reddit Monitoring Solution</title>
        <meta name="description" content="Learn about Notifyr, a powerful tool for monitoring Reddit posts and comments. Get instant notifications for keywords in your favorite subreddits." />
        <meta name="keywords" content="Notifyr, about, monitoring, notifications, keywords, subreddits, Reddit alerts" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-blue-100 to-blue-50">
        <Navbar />
        <div className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl font-extrabold text-blue-900 text-center mb-12">About Notifyr</h2>
            
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-lg shadow-xl p-8 w-full max-w-3xl mx-auto relative z-10"
            >
              <h3 className="text-2xl font-bold mb-6 text-blue-900">Our Story</h3>
              <p className="text-gray-700 mb-6">
                Notifyr started as a solution for Redditors who were tired of missing out on important posts and deals. We frequent subreddits like r/pkmntcgtrades and r/GunAccessoriesForSale, and it was frustrating to constantly refresh pages hoping to catch deals, especially given Reddit's limited search capabilities.
              </p>
              <p className="text-gray-700 mb-6">
                After missing one too many good deals, we decided to create a simple alert system to notify us when specific keywords appeared in our favorite subreddits.
              </p>
              <p className="text-gray-700 mb-6">
                What began as a personal project quickly evolved into Notifyr, a comprehensive Reddit monitoring tool that's now helping Redditors across various communities stay on top of the content that matters most to them.
              </p>
              <h3 className="text-2xl font-bold mb-6 text-blue-900">Core Features</h3>
              <ul className="list-none space-y-4">
                <Feature icon={<Bell />} title="Custom Alerts">
                  Set up personalized alerts for specific keywords in any subreddit you care about.
                </Feature>
                <Feature icon={<Inbox />} title="Smart Batching">
                  Our system groups notifications intelligently to keep you informed without overwhelming your inbox.
                </Feature>
                <Feature icon={<Zap />} title="Fast Processing Time">
                  Lightning-fast processing ensures you get alerts as quickly as possible.
                </Feature>
                <Feature icon={<Heart />} title="Community-Driven">
                  Built by Redditors, for Redditors. Constantly improving based on real user feedback.
                  <Link to="/contact" className="block mt-2 text-blue-500 hover:text-blue-600">
                    Contact us with your feedback
                  </Link>
                </Feature>
              </ul>
            </motion.div>

            <div className="text-center mt-12">
              <p className="text-xl text-blue-700">
                Join us in never missing another important post from your favorite subreddits!
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const Feature = ({ icon, title, children }) => (
  <li className="flex items-start">
    <div className="flex-shrink-0 text-blue-500 mr-3">
      {React.cloneElement(icon, { size: 24 })}
    </div>
    <div>
      <h4 className="text-lg font-semibold text-blue-900">{title}</h4>
      <div className="text-gray-700">{children}</div>
    </div>
  </li>
);

export default AboutPage;