import { gradientCombos } from './constants';

export const minutesToUIFormat = (minutes) => {
  if (minutes === 0) return "0m";
  if (minutes < 60) return `${minutes}m`;
  if (minutes < 1440) { // Less than 24 hours
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes ? `${hours}h${remainingMinutes}m` : `${hours}h`;
  }
  // For 24 hours or more, just return in hours
  return `${Math.floor(minutes / 60)}h`;
};

export const uiFormatToMinutes = (uiFormat) => {
  if (uiFormat === "0m") return 0;
  const hours = uiFormat.includes('h') ? parseInt(uiFormat.split('h')[0]) : 0;
  const minutes = uiFormat.includes('m') ? parseInt(uiFormat.split('m')[0]) : 0;
  return hours * 60 + minutes;
};

export const getRandomGradient = () => {
  const randomIndex = Math.floor(Math.random() * gradientCombos.length);
  return gradientCombos[randomIndex];
};
		