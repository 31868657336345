import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footers';

const TermsOfServicePage = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service - Notifyr</title>
        <meta name="description" content="Review Notifyr's terms of service for our Reddit keyword alert platform. Understand your rights and responsibilities when using our service." />
        <meta name="keywords" content="terms of service, Notifyr, user agreement, Reddit alerts, legal terms" />
      </Helmet>
      <div className="min-h-screen bg-gray-100">
        <Navbar />
        <div className="h-24"></div>
        <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">Terms of Service</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Acceptance of Terms</h2>
            <p className="text-gray-700">By accessing or using Notifyr, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our service.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Description of Service</h2>
            <p className="text-gray-700">Notifyr is a service that monitors Reddit for user-specified keywords and sends notifications based on user preferences. Users can add keywords and select subreddits to monitor.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. User Accounts</h2>
            <p className="text-gray-700">To use Notifyr, you must create an account. You are responsible for maintaining the confidentiality of your account and for all activities that occur under your account.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. User Responsibilities</h2>
            <p className="text-gray-700">You agree to use Notifyr in compliance with all applicable laws and Reddit's terms of service. You are solely responsible for your use of the service and any content you submit or display via the service.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Prohibited Uses</h2>
            <p className="text-gray-700">You may not use Notifyr for any illegal or unauthorized purpose. Prohibited activities include, but are not limited to:</p>
            <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
              <li>Violating any laws or regulations</li>
              <li>Interfering with the Reddit platform or connected networks</li>
              <li>Impersonating any person or entity</li>
              <li>Using the service to harass or harm others</li>
              <li>Collecting personal data about other users without permission</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Intellectual Property</h2>
            <p className="text-gray-700">Notifyr and its original content, features, and functionality are owned by Notifyr and are protected by international copyright, trademark, and other intellectual property laws.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Termination</h2>
            <p className="text-gray-700">We may terminate or suspend your account and access to the service immediately, without prior notice or liability, for any reason, including a breach of these Terms of Service.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Limitation of Liability</h2>
            <p className="text-gray-700">Notifyr shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of or inability to use the service.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Disclaimer</h2>
            <p className="text-gray-700">Notifyr is provided "AS IS" and "AS AVAILABLE" without warranties of any kind, either express or implied.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">10. Governing Law</h2>
            <p className="text-gray-700">These Terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">11. Changes to Terms</h2>
            <p className="text-gray-700">We reserve the right to modify these Terms of Service at any time. We will provide notice of any material changes at least 30 days before they take effect.</p>
          </section>

          <p className="text-gray-700 mt-8">
            By continuing to use Notifyr after any changes to these Terms, you agree to be bound by the revised terms.
          </p>

          <p className="text-gray-700 mt-4">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="mt-8">
            <Link to="/privacy-policy" className="text-blue-600 hover:text-blue-800 mr-4">View our Privacy Policy</Link>
            <Link to="/refund-policy" className="text-blue-600 hover:text-blue-800">View our Refund Policy</Link>
          </div>
        </div>

        <Footer/>
      </div>
    </>
  );
};

export default TermsOfServicePage;