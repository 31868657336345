import React, { useState } from 'react';
import { Moon, Sun, Wand2, EyeOff, Eye } from 'lucide-react';


const NeonGlassCard = ({ isDarkMode }) => {
  return (
    <div
      className={`w-80 p-6 flex flex-col justify-between material-shadow ${
        isDarkMode ? 'text-white' : 'text-gray-800'
      }`}
      style={{
        background: isDarkMode ? 'rgba(54, 69, 79, 0.3)' : 'rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(20px)',
        WebkitBackdropFilter: 'blur(20px)',
        borderRadius: '10px',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        boxShadow: isDarkMode
          ? `
            inset 0 6px 12px rgba(255, 255, 255, 0.2),
            inset 0 -40px 40px rgba(0, 0, 0, 0.1),
            inset 0 -6px 18px rgba(255, 255, 255, 0.2),
            inset 0 40px 40px rgba(255, 255, 255, 0.12),
            inset 0 2px 1px rgba(255, 255, 255, 0.12)
          `
          : `
            0px 0px 0px 1px rgba(3, 239, 254, 0.04),
            0px 1px 1px -0.5px rgba(35, 43, 45, 0.04),
            0px 3px 3px -1.5px rgba(34, 34, 46, 0.04),
            0px 6px 6px -3px rgba(34, 34, 46, 0.04),
            0px 12px 12px -6px rgba(3, 239, 254, 0.04),
            0px 24px 24px -12px rgba(3, 239, 254, 0.04)
          `,
        minHeight: '400px',
      }}
    >
      <h2 className="text-2xl font-bold mb-4">Neon Glass Card</h2>
      <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
        This is a neon glassmorphism card with a unique inner shadow effect.
      </p>
      <div className="mt-4">
        <button
          className="w-full px-6 py-3 rounded-full text-white font-semibold flex items-center justify-center space-x-2 focus:outline-none relative overflow-hidden"
          style={{
            background: 'radial-gradient(circle, #A47CF3, #683FEA)',
            boxShadow: `
              inset 0 1px 0 0 rgba(255,255,255,0.4),
              inset 0 -4px 0 0 rgba(0,0,0,0.2),
              0 0 0 4px rgba(255,255,255,0.07),
              0 0 180px 0 rgba(153,23,255,1)
            `,
          }}
        >
          <span className="relative z-10 flex items-center space-x-2">
            <Wand2 className="w-5 h-5" />
            <span>Generate</span>
          </span>
          <div
            className="absolute inset-0 opacity-30"
            style={{
              background: 'linear-gradient(to right, transparent, rgba(255,255,255,0.3), transparent)',
              transform: 'skewX(-20deg) translateX(-100%)',
              animation: 'shine 3s infinite',
            }}
          />
        </button>
      </div>
      <style jsx>{`
        @keyframes shine {
          0% {
            transform: skewX(-20deg) translateX(-100%);
          }
          100% {
            transform: skewX(-20deg) translateX(300%);
          }
        }
      `}</style>
    </div>
  );
};


const GlassmorphismPlayground = ({ CustomComponent }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showBlobs, setShowBlobs] = useState(true);
  const [showPastelCircles, setShowPastelCircles] = useState(false);

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
  const toggleBlobs = () => setShowBlobs(!showBlobs);
  const togglePastelCircles = () => setShowPastelCircles(!showPastelCircles);

  return (
    <div className={`relative w-full h-screen overflow-hidden ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      {/* Graph paper grid */}
      <div className="absolute inset-0" style={{
          backgroundImage: `
            linear-gradient(to right, ${isDarkMode ? 'rgba(55, 65, 81, 0.3)' : 'rgba(229, 231, 235, 0.5)'} 1px, transparent 1px),
            linear-gradient(to bottom, ${isDarkMode ? 'rgba(55, 65, 81, 0.3)' : 'rgba(229, 231, 235, 0.5)'} 1px, transparent 1px)
          `,
          backgroundSize: '20px 20px'
        }}
      />

      {/* Pastel Gradient Circles */}
      {showPastelCircles && (
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-1/4 left-1/4" style={{
            background: 'linear-gradient(to bottom right, #9600FF, #AEBAF8)',
            width: '300px',
            height: '300px',
            borderRadius: '50%',
            filter: 'blur(0px)'
          }}></div>
          <div className="absolute bottom-2/3 right-2/3" style={{
            background: 'linear-gradient(to bottom right, #F6EA41, #F048C6)',
            width: '300px',
            height: '300px',
            borderRadius: '50%',
            filter: 'blur(0px)'
          }}></div>
          <div className="absolute top-2/3 left-2/3" style={{
            background: 'linear-gradient(to bottom right, #0CCDA3, #C1FCD3)',
            width: '300px',
            height: '300px',
            borderRadius: '50%',
            filter: 'blur(0px)'
          }}></div>
          <div className="absolute bottom-1/3 right-1/4" style={{
            background: 'linear-gradient(to bottom right, #F9957F, #F2F5D0)',
            width: '300px',
            height: '300px',
            borderRadius: '50%',
            filter: 'blur(0px)'
          }}></div>
        </div>
      )}

      {/* Vibrant Blurred Blobs */}
      {showBlobs && (
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-pink-600 opacity-40 rounded-full blur-3xl"></div>
          <div className="absolute bottom-1/3 right-1/4 w-96 h-96 bg-blue-600 opacity-40 rounded-full blur-3xl"></div>
          <div className="absolute top-2/3 left-2/3 w-80 h-80 bg-purple-600 opacity-40 rounded-full blur-3xl"></div>
        </div>
      )}

      

      {/* Neon Glassmorphism Card 
      If dark mode change the background to background: 'rgba(0, 0,0, 0.3)', and dont include the box shadow
      If light mode keep background as 'rgba(255, 255,255, 0.3)' and keep box shadow
      */}
      <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <NeonGlassCard isDarkMode={isDarkMode}/>
      </div>

      {/* Light/Dark mode toggle */}
      <div className="absolute top-4 right-4">
        <button onClick={toggleDarkMode} className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-white'} material-shadow focus:outline-none`}>
          {isDarkMode ? <Sun className="w-6 h-6 text-yellow-400" /> : <Moon className="w-6 h-6 text-gray-800" />}
        </button>
      </div>

      {/* Blob and Pastel Circle Toggles */}
      <div className="absolute top-4 left-4 flex space-x-4">
        <button onClick={toggleBlobs} className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-white'} material-shadow focus:outline-none`}>
          {showBlobs ? <EyeOff className={`w-6 h-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`} /> : <Eye className={`w-6 h-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`} />}
        </button>
        <button onClick={togglePastelCircles} className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-white'} material-shadow focus:outline-none`}>
          {showPastelCircles ? <EyeOff className={`w-6 h-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`} /> : <Eye className={`w-6 h-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`} />}
        </button>
      </div>

      <style jsx>{`
        @keyframes shine {
          0% {
            transform: skewX(-20deg) translateX(-100%);
          }
          100% {
            transform: skewX(-20deg) translateX(300%);
          }
        }
      `}</style>
    </div>
  );
};

export default GlassmorphismPlayground;
