import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/Homepage';

import LoginPage from './pages/Auth/Login';
import RegisterPage from './pages/Auth/Register';

import PricingPage from './pages/Pricing';
import AboutPage from './pages/About';

import { ThemeProvider } from './components/ThemeProvider';

import GlassmorphismPlayground from './pages/Playground';
import AlertCard from './pages/Playground/AlertCard';
import AIInterface from './pages/Playground/AIInterface';
import { AuthProvider } from './AuthContext'; // Adjust the path as needed

import CheckoutSuccessPage from './pages/Success';
import AppPage from './pages/App';

import KeywordPage from './pages/Keywords';
import AccountPage from './pages/Account';

import { PaddleProvider } from './PaddleContext';

// Legal
// import TermsAndConditionsPage from './pages/Legal/TermsOfService';
import PrivacyPolicyPage from './pages/Legal/PrivacyPolicy';
import RefundPolicyPage from './pages/Legal/RefundPolicy';
import ContactPage from './pages/Contact';
import TermsOfServicePage from './pages/Legal/TermsOfService';

import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <PaddleProvider>
          <ThemeProvider>
            <Router>
              <Routes>
                
                <Route path="/" element={<HomePage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/about" element={<AboutPage />} />
                
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/playground" element={<GlassmorphismPlayground />} />
                <Route path="/prompt" element={<AlertCard />} />
                <Route path="/ai" element={<AIInterface />} />
                <Route path="/success" element={<CheckoutSuccessPage />} />
                <Route path="/alerts" element={<AppPage />} />
                <Route path="/keywords" element={<KeywordPage />} />
                <Route path="/account" element={<AccountPage />} />

                <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/refund-policy" element={<RefundPolicyPage />} />

                <Route path="/contact" element={<ContactPage />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </PaddleProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;