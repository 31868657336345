// src/hooks/useColorMode.js
import { useState, useEffect } from 'react';

import { colorConfig } from '../styles/ColorConfig';

export const useColorMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Initialize state with the value from localStorage, or false if not set
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  const [colors, setColors] = useState(isDarkMode ? colorConfig.dark : colorConfig.light);

  useEffect(() => {
    // Update localStorage when isDarkMode changes
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    setColors(isDarkMode ? colorConfig.dark : colorConfig.light);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return { isDarkMode, toggleDarkMode, colors };
};