import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Importing Helmet for managing meta tags
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footers';
import Hero from './Hero';
import FeatureSection from './FeatureSection'; // Import the new FeatureSection
import FeatureComparisonSection from './FeatureComparisonSection';
import UserDealsSection from './UserDealsSection';
import TestimonialsSection from './TestimonialsSection';
import CTASection from './CTASection';
import SectionWrapper from './SectionWrapper';

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Notifyr - Stay Ahead on Reddit with Instant Alerts</title>
        <meta name="description" content="Notifyr is your ultimate Reddit alert app. Monitor keywords, get instant notifications, and never miss out on the hottest deals and discussions in your favorite subreddits." /> 
        <meta name="keywords" content="Reddit alerts, keyword monitoring, subreddit notifications, Reddit deals, Notifyr, instant alerts, stay informed, Reddit app" /> 
        <meta name="author" content="Notifyr Team" /> 
        <meta property="og:title" content="Notifyr - Stay Ahead on Reddit with Instant Alerts" /> 
        <meta property="og:description" content="Join thousands of users who rely on Notifyr to monitor keywords and stay updated on Reddit. Get alerts for the latest posts and discussions." /> 
        <meta property="og:image" content="/img/notifyr-logo.png" /> 
        <meta property="og:url" content="https://www.notifyr.com" />
        <meta name="twitter:card" content="summary_large_image" /> 
        <meta name="twitter:title" content="Notifyr - Stay Ahead on Reddit with Instant Alerts" /> 
        <meta name="twitter:description" content="Monitor keywords and get instant alerts on Reddit with Notifyr. Stay informed and never miss a deal!" /> 
        <meta name="twitter:image" content="/img/notifyr-logo.png" />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <SectionWrapper>
          <Hero />
        </SectionWrapper>
        <SectionWrapper id="features">
          <FeatureSection />
        </SectionWrapper>
        <SectionWrapper id="comparison">
          <FeatureComparisonSection />
        </SectionWrapper>
        <SectionWrapper id="subreddits">
          <UserDealsSection />
        </SectionWrapper>
        <SectionWrapper id="testimonials">
          <TestimonialsSection />
        </SectionWrapper>
        <SectionWrapper>
          <CTASection />
        </SectionWrapper>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
