import React from 'react';
import { X } from 'lucide-react';

const Chip = ({ text, onDelete, isAnd }) => (
  <span className={`inline-flex items-center px-2 py-1 rounded-md shadow-md text-sm font-medium mr-2 mb-2 ${isAnd ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
    {text}
    <button onClick={onDelete} className="ml-1 focus:outline-none">
      <X size={14} />
    </button>
  </span>
);

export default Chip;