import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Zap, Search, Layers, Sliders } from 'lucide-react';

const FeatureSection = () => {
  const [activeFeature, setActiveFeature] = useState(0);

  const features = [
    {
      icon: <Zap className="h-8 w-8" />,
      title: "Lightning-Fast Processing",
      description: "Experience unparalleled speed with our sub-10ms processing time. Be the first to know about new posts and never miss an opportunity.",
      color: "text-blue-600",
      bgColor: "from-blue-400/20 to-blue-600/20",
      highlight: "Sub-10ms processing",
      whyItMatters: [
        "React instantly to new opportunities",
        "Stay ahead of other users in fast-moving subreddits",
        "Maximize your chances of being first to respond"
      ]
    },
    {
      icon: <Search className="h-8 w-8" />,
      title: "Advanced Keyword Matching",
      description: "Utilize powerful AND/OR operators in your keyword searches. Fine-tune your alerts to catch exactly what you're looking for.",
      color: "text-purple-600",
      bgColor: "from-purple-400/20 to-purple-600/20",
      highlight: "AND/OR operators",
      whyItMatters: [
        "Refine your searches for more accurate results",
        "Reduce noise and irrelevant notifications",
        "Customize your alerts to match your specific needs"
      ]
    },
    {
      icon: <Layers className="h-8 w-8" />,
      title: "Smart Keyword Batching",
      description: "Our intelligent system groups related keywords together, optimizing your searches and reducing noise in your notifications.",
      color: "text-green-600",
      bgColor: "from-green-400/20 to-green-600/20",
      highlight: "Intelligent grouping",
      whyItMatters: [
        "Efficiently manage multiple related keywords",
        "Streamline your notification process",
        "Improve overall search performance"
      ]
    },
    {
      icon: <Sliders className="h-8 w-8" />,
      title: "Seamless User Interface",
      description: "Enjoy a smooth, intuitive experience with our carefully crafted UI. Set up alerts, manage keywords, and view results with ease.",
      color: "text-pink-600",
      bgColor: "from-pink-400/20 to-pink-600/20",
      highlight: "Intuitive design",
      whyItMatters: [
        "Save time with easy-to-use controls",
        "Quickly adapt and modify your monitoring setup",
        "Focus on results, not on learning complex interfaces"
      ]
    },
  ];

  return (
    <div className="relative min-h-screen py-24 bg-gradient-to-b from-blue-100 to-purple-100 overflow-hidden flex items-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl sm:text-5xl font-extrabold text-blue-900 mb-4">
            Powerful Features for Reddit Monitoring
          </h2>
          <p className="text-xl text-blue-800 max-w-3xl mx-auto">
            Our cutting-edge technology ensures you never miss a beat on Reddit.
          </p>
        </motion.div>

        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-200 via-purple-200 to-pink-200 opacity-30 blur-3xl"></div>
          
          <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="space-y-4 h-full flex flex-col justify-between">
              {features.map((feature, index) => (
                <FeatureButton
                  key={index}
                  feature={feature}
                  isActive={activeFeature === index}
                  onClick={() => setActiveFeature(index)}
                />
              ))}
            </div>
            <div className="relative h-full">
              <AnimatePresence mode="wait">
                <FeatureDisplay key={activeFeature} feature={features[activeFeature]} />
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureButton = ({ feature, isActive, onClick }) => (
  <motion.button
    className={`w-full text-left p-4 rounded-lg backdrop-blur-md bg-white border border-white border-opacity-20 shadow-lg
                ${isActive ? 'bg-opacity-30 shadow-inner' : 'bg-opacity-70 hover:bg-opacity-10'}`}
    onClick={onClick}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
  >
    <div className="flex items-center">
      <div className={`${feature.color} mr-4`}>{feature.icon}</div>
      <span className={`font-semibold ${feature.color}`}>{feature.title}</span>
    </div>
  </motion.button>
);

const FeatureDisplay = ({ feature }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    className="h-full p-6 rounded-lg bg-white shadow-lg flex flex-col justify-between relative overflow-hidden"
    style={{
      boxShadow: `0 4px 6px rgba(0, 0, 0, 0.1), 
                  0 1px 3px rgba(0, 0, 0, 0.08)`
    }}
  >
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: 0.2, duration: 0.3 }}
    >
      <h3 className={`text-2xl font-bold ${feature.color} mb-2`}>{feature.title}</h3>
      <div className="inline-block mb-4">
        <div className={`px-3 py-1 rounded-md bg-gradient-to-r ${feature.bgColor} text-sm font-semibold ${feature.color}`}>
          {feature.highlight}
        </div>
      </div>
      <p className="text-gray-800 mb-6">{feature.description}</p>
    </motion.div>
    <motion.div 
      className="mt-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4, duration: 0.3 }}
    >
      <h4 className="text-lg font-semibold mb-2 text-gray-800">Why it matters</h4>
      <ul className="space-y-2">
        {feature.whyItMatters.map((item, index) => (
          <li key={index} className="flex items-start">
            <svg className="h-5 w-5 text-green-500 mr-2 mt-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span className="text-sm text-gray-700">{item}</span>
          </li>
        ))}
      </ul>
    </motion.div>
  </motion.div>
);

export default FeatureSection;
