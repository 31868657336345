import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

const Notification = ({ message, type, duration = 5000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const bgColor = type === 'error' 
    ? 'bg-red-100 border-red-400 text-red-700' 
    : 'bg-green-100 border-green-400 text-green-700';

  return (
    <motion.div 
      initial={{ opacity: 0, x: 20, scale: 0.3 }}
      animate={{ opacity: 1, x: 0, scale: 1 }}
      exit={{ opacity: 0, x: '100%', scale: 0.5 }}
      transition={{ 
        duration: 0.4,
        ease: "easeInOut"
      }}
      className={`fixed bottom-5 right-5 ${bgColor} border-l-4 px-4 py-3 rounded-md shadow-md`}
      role="alert"
    >
      <p>{message}</p>
    </motion.div>
  );
};

export default Notification;