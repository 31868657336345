import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const AIInterface = ({ 
  analysisSteps = [
    { step: "Analyzing Comment Tree 1", quote: "This movie was fantastic!", signal: 0 },
    { step: "Analyzing Comment Tree 2", quote: "The plot twists kept me on the edge of my seat.", signal: 0 },
    { step: "Finished analyzing thread", quote: "Overall positive sentiment detected.", signal: 1 }
  ],
  finalResult = "People believe this is a good movie" 
}) => {
  const [currentText, setCurrentText] = useState("");
  const [isBlinking, setIsBlinking] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [isAnalyzing, setIsAnalyzing] = useState(true);
  const [cards, setCards] = useState([]);
  const analyzingText = "Analyzing";

  useEffect(() => {
    let interval;
    if (isAnalyzing) {
      let index = 0;
      let addingDots = false;
      interval = setInterval(() => {
        if (!addingDots) {
          if (index <= analyzingText.length) {
            setCurrentText(analyzingText.slice(0, index));
            index++;
          } else {
            addingDots = true;
            index = 0;
          }
        } else {
          if (index < 3) {
            setCurrentText(prev => prev + '.');
            index++;
          } else {
            addingDots = false;
            index = 0;
            setCurrentText('');
          }
        }
      }, 200);
    } else {
      let index = 0;
      interval = setInterval(() => {
        if (index <= finalResult.length) {
          setCurrentText(finalResult.slice(0, index));
          index++;
        } else {
          clearInterval(interval);
        }
      }, 100);
    }
    return () => clearInterval(interval);
  }, [isAnalyzing, finalResult]);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setIsBlinking((prev) => !prev);
    }, 500);
    return () => clearInterval(blinkInterval);
  }, []);

  useEffect(() => {
    if (currentStep < analysisSteps.length) {
      setCards((prev) => [...prev.slice(0, currentStep), analysisSteps[currentStep]]);
      if (analysisSteps[currentStep].signal === 1) {
        setIsAnalyzing(false);
        setCurrentText(analysisSteps[currentStep].quote);
      }
    }
  }, [currentStep, analysisSteps]);

  const handleStepForward = () => {
    if (currentStep < analysisSteps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleStepBackward = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      setIsAnalyzing(true);
      setCurrentText("");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <motion.div 
        className="font-mono text-2xl text-black max-w-2xl text-center mb-8"
        initial={{ y: 0 }}
        animate={{ y: -cards.length * 20 }}
        transition={{ type: "spring", stiffness: 100, damping: 15 }}
      >
        {currentText}
        <span className={isBlinking ? "opacity-100" : "opacity-0"}>_</span>
      </motion.div>
      <div className="relative w-full max-w-md space-y-4 mb-8">
        <AnimatePresence>
          {cards.map((card, index) => (
            <motion.div
              key={index}
              className="bg-white p-4 rounded-lg shadow-lg w-full"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <h3 className="font-bold text-lg mb-2">{card.step}</h3>
              <p className="text-gray-600 italic">"{card.quote}"</p>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      <div className="flex space-x-4">
        <button
          onClick={handleStepBackward}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={currentStep === 0}
        >
          <ChevronLeft />
        </button>
        <button
          onClick={handleStepForward}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={currentStep === analysisSteps.length - 1}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default AIInterface;