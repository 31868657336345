import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, Link, Tag, DollarSign, User, Mail } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footers';
import useAuthenticatedApi from '../../hooks/useAuthenticatedApi';

const ContactPage = () => {
  const [isContact, setIsContact] = useState(true);
  const [contactType, setContactType] = useState('bug');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [redditUrl, setRedditUrl] = useState('');
  const [productName, setProductName] = useState('');
  const [retailPrice, setRetailPrice] = useState('');
  const [dealPrice, setDealPrice] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [securityCheck, setSecurityCheck] = useState('');

  const { executeRequest } = useAuthenticatedApi();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const deal = urlParams.get('deal');
    const type = urlParams.get('type');
    if (deal === 'true') setIsContact(false);
    if (type && ['bug', 'feature', 'support', 'account'].includes(type)) setContactType(type);
  }, []);

  const validateInputs = () => {
    if (isContact) {
      if (!name.trim() || !email.trim() || !message.trim()) {
        setSubmitError("All fields are required");
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setSubmitError("Invalid email format");
        return false;
      }
    } else {
      if (!redditUrl.trim() || !productName.trim() || !retailPrice || !dealPrice) {
        setSubmitError("All fields are required");
        return false;
      }
      if (!/^https?:\/\/(www\.)?reddit\.com/.test(redditUrl)) {
        setSubmitError("Invalid Reddit URL");
        return false;
      }
      if (isNaN(parseFloat(retailPrice)) || isNaN(parseFloat(dealPrice))) {
        setSubmitError("Prices must be valid numbers");
        return false;
      }
      if (parseFloat(dealPrice) >= parseFloat(retailPrice)) {
        setSubmitError("Deal price must be lower than retail price");
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;
    
    if (securityCheck) {
      console.log('Potential spam submission detected');
      setSubmitError("Submission successful!");
      return;
    }

    const formData = isContact 
      ? { name, email, message, contactType, securityCheck }
      : { redditUrl, productName, retailPrice, dealPrice, securityCheck };

    try {
      await executeRequest(async () => {
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...formData, isContact }),
        });

        const data = await response.json();

        if (response.ok) {
          // Reset form fields and show success message
          setName('');
          setEmail('');
          setMessage('');
          setRedditUrl('');
          setProductName('');
          setRetailPrice('');
          setDealPrice('');
          setSecurityCheck('');
          setSubmitError("Submission successful!");
        } else {
          throw new Error(data.error || "An error occurred. Please try again.");
        }
      });
    } catch (error) {
      setSubmitError("An error occurred. Please try again.");
    }
  };

  const renderForm = () => (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md relative z-10"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-blue-900">
        {isContact ? 'Reach Out to Us' : 'Submit a Deal'}
      </h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="security_check"
          value={securityCheck}
          onChange={(e) => setSecurityCheck(e.target.value)}
          style={{display: 'none'}}
          tabIndex="-1"
          autoComplete="off"
        />
        {isContact ? (
          <>
            <InputField icon={<User />} type="text" value={name} onChange={setName} placeholder="Enter your name" />
            <InputField icon={<Mail />} type="email" value={email} onChange={setEmail} placeholder="Enter your email" />
            <SelectField icon={<Tag />} value={contactType} onChange={setContactType} options={[
              { value: 'bug', label: 'Bug Report' },
              { value: 'feature', label: 'Feature Request' },
              { value: 'support', label: 'General Support' },
              { value: 'account', label: 'Account Support' },
            ]} />
            <TextAreaField icon={<MessageSquare />} value={message} onChange={setMessage} placeholder="Enter your message" />
          </>
        ) : (
          <>
            <InputField icon={<Link />} type="url" value={redditUrl} onChange={setRedditUrl} placeholder="Reddit URL or Subreddit name" />
            <InputField icon={<Tag />} type="text" value={productName} onChange={setProductName} placeholder="Product Name" />
            <InputField icon={<DollarSign />} type="number" value={retailPrice} onChange={setRetailPrice} placeholder="Retail Price" />
            <InputField icon={<DollarSign />} type="number" value={dealPrice} onChange={setDealPrice} placeholder="Deal Price" />
          </>
        )}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          {isContact ? 'Send Message' : 'Submit Deal'}
        </button>
      </form>
      {submitError && <p className={`text-center mt-4 ${submitError.includes("successful") ? "text-green-500" : "text-red-500"}`}>{submitError}</p>}
    </motion.div>
  );

  return (
    <>
      <Helmet>
        <title>Contact Us - Notifyr</title>
        <meta name="description" content="Get in touch with Notifyr. We're here to help with support, feature requests, or any questions you may have about our Reddit monitoring service." />
        <meta name="keywords" content="contact, support, Notifyr, feature request, help, Reddit alerts" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-blue-100 to-blue-50">
        <Navbar />
        <div className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl font-extrabold text-blue-900 text-center mb-12">We're Here to Help!</h2>
            <p className="text-xl text-blue-700 text-center mb-12">
              Whether you need support, want to share a great Reddit deal, or have ideas to make our community even better, we're all ears. Your input helps us grow and improve Notifyr!
            </p>
            
            <div className="flex justify-center mb-12">
              <div className="bg-blue-200 p-1 rounded-full">
                <button
                  className={`px-4 py-2 rounded-full ${
                    isContact ? 'bg-white shadow-sm text-blue-900' : 'text-blue-700'
                  }`}
                  onClick={() => setIsContact(true)}
                >
                  Contact
                </button>
                <button
                  className={`px-4 py-2 rounded-full ${
                    !isContact ? 'bg-white shadow-sm text-blue-900' : 'text-blue-700'
                  }`}
                  onClick={() => setIsContact(false)}
                >
                  Submit Deal
                </button>
              </div>
            </div>

            <div className="flex justify-center">
              {renderForm()}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const InputField = ({ icon, type, value, onChange, placeholder }) => (
  <div className="mb-4">
    <div className="relative">
      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-400">
        {React.cloneElement(icon, { size: 18 })}
      </div>
      <input
        type={type}
        className="w-full pl-10 pr-3 py-3 rounded-lg border border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-base"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        required
      />
    </div>
  </div>
);

const SelectField = ({ icon, value, onChange, options }) => (
  <div className="mb-4">
    <div className="relative">
      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-400">
        {React.cloneElement(icon, { size: 18 })}
      </div>
      <select
        className="w-full pl-10 pr-3 py-3 rounded-lg border border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-base appearance-none"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-blue-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
        </svg>
      </div>
    </div>
  </div>
);

const TextAreaField = ({ icon, value, onChange, placeholder }) => (
  <div className="mb-6">
    <div className="relative">
      <div className="absolute left-3 top-3 text-blue-400">
        {React.cloneElement(icon, { size: 18 })}
      </div>
      <textarea
        className="w-full pl-10 pr-3 py-3 rounded-lg border border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-base"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        rows="4"
        required
      />
    </div>
  </div>
);

export default ContactPage;