import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import { Medal, Edit, ArrowRight, ArrowUpCircle, Info } from 'lucide-react';
import Navbar from '../../Navbar';
import { motion, AnimatePresence } from 'framer-motion';
import Modal from '../../components/Modal';
import Notification from '../../components/Notification';
import useAuthenticatedApi from '../../hooks/useAuthenticatedApi';
import { Helmet } from 'react-helmet-async';

const AccountPage = () => {
  const { user } = useAuth();
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isRemovingCancellation, setIsRemovingCancellation] = useState(false);
  const [cancellationError, setCancellationError] = useState(null);
  const [notification, setNotification] = useState(null);
  const { authFetch, executeRequest } = useAuthenticatedApi();

  useEffect(() => {
    const fetchUserSubscription = async () => {
      if (!user?.id) {
        setIsLoading(false);
        return;
      }
  
      setIsLoading(true);
      try {
        const data = await executeRequest(async () => {
          return await authFetch(`/api/users/subscription?uuid=${user.id}`);
        });
        setSubscription(data);
      } catch (error) {
        console.error('Error fetching subscription:', error);
        showNotification('Error fetching subscription data', 'error');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchUserSubscription();
  }, [user?.id, authFetch, executeRequest]);

  const showNotification = (message, type = 'info') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 5000);
  };

  const handleManageSubscription = () => {
    if (subscription?.subscription.tier === 'BRONZE' || subscription?.subscription.price === 0) {
      window.location.href = '/pricing';
    } else {
      setIsModalOpen(true);
    }
  };

  const handleUpgradeSubscription = () => {
    window.location.href = '/pricing';
  };

  const handleCancelSubscription = async () => {
    if (!user || !user.id) return;
    setIsCancelling(true);
    setCancellationError(null);

    try {
      await executeRequest(async () => {
        await authFetch(`/api/users/subscription/cancel?uuid=${user.id}`, {
          method: 'POST',
        });
      });

      await refetchSubscription();
      setIsModalOpen(false);
      showNotification('Subscription cancelled successfully', 'success');
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setCancellationError('Failed to cancel subscription. Please try again.');
      showNotification('Failed to cancel subscription', 'error');
    } finally {
      setIsCancelling(false);
    }
  };

  const handleRemoveCancellation = async () => {
    if (!user || !user.id) return;
    setIsRemovingCancellation(true);
    setCancellationError(null);
  
    try {
      await executeRequest(async () => {
        await authFetch(`/api/users/subscription/remove_cancellation?uuid=${user.id}`, {
          method: 'POST',
        });
      });
  
      await refetchSubscription();
      setIsModalOpen(false);
      showNotification('Cancellation removed successfully', 'success');
    } catch (error) {
      console.error('Error removing cancellation:', error);
      setCancellationError('Failed to remove cancellation. Please try again.');
      showNotification('Failed to remove cancellation', 'error');
    } finally {
      setIsRemovingCancellation(false);
    }
  };

  const refetchSubscription = async () => {
    try {
      const updatedSubscription = await executeRequest(async () => {
        return await authFetch(`/api/users/subscription?uuid=${user.id}`);
      });
      setSubscription(updatedSubscription);
    } catch (error) {
      console.error('Error fetching updated subscription:', error);
      showNotification('Error updating subscription data', 'error');
    }
  };

  const getTierColors = (tier) => {
    switch (tier) {
      case 'GOLD':
        return {
          bg: 'bg-yellow-100',
          text: 'text-yellow-800',
          progressBg: 'bg-yellow-300',
          progressFill: 'bg-yellow-500'
        };
      case 'SILVER':
        return {
          bg: 'bg-gray-100',
          text: 'text-gray-800',
          progressBg: 'bg-gray-300',
          progressFill: 'bg-gray-500'
        };
      case 'BRONZE':
        return {
          bg: 'bg-amber-100',
          text: 'text-amber-800',
          progressBg: 'bg-amber-300',
          progressFill: 'bg-amber-500'
        };
      default:
        return {
          bg: 'bg-amber-100',
          text: 'text-amber-800',
          progressBg: 'bg-amber-300',
          progressFill: 'bg-amber-500'
        };
    }
  };

  const getBillingIntervalDisplay = () => {
    const interval = subscription?.subscription.billing_interval?.interval;
    const frequency = subscription?.subscription.billing_interval?.frequency;
    if (interval === 'month' && frequency === 1) {
      return '/month';
    } else if (interval === 'year' && frequency === 1) {
      return '/year';
    } else {
      return `/${interval}`;
    }
  };

  const getSubscriptionStatus = () => {
    const { scheduled_change, scheduled_change_reason } = subscription?.subscription || {};
    if (scheduled_change_reason === 'cancel' && scheduled_change) {
      const date = new Date(scheduled_change);
      return `Cancelling on ${date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`;
    }
    return 'Active';
  };

  const getNextBillingDate = () => {
    const nextBilledAt = subscription?.subscription.next_billed_at;
    if (nextBilledAt) {
      const date = new Date(nextBilledAt);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
    return 'N/A';
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center">
        <div className="text-2xl font-semibold text-gray-600">Loading...</div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center">
        <div className="text-2xl font-semibold text-gray-600">Please log in to view your account.</div>
      </div>
    );
  }

  const colors = getTierColors(subscription?.subscription.tier);

  return (
    <>
      <Helmet>
        <title>Notifyr - Your Account</title>
        <meta name="description" content="Manage your Notifyr account settings and subscription." />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <AnimatePresence>
          <motion.main
            className="container mx-auto px-4 py-8 max-w-4xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.h1 
              className="text-3xl font-bold text-gray-900 mb-8"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Your Account
            </motion.h1>
            
            <div className="space-y-8">
            <motion.div 
    className={`${colors.bg} ${colors.text} shadow-lg rounded-lg overflow-hidden p-6`}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <Medal className="w-8 h-8 mr-3" />
        <h2 className="text-2xl font-bold">
          {subscription?.subscription.tier} Plan
        </h2>
      </div>
      <div className="text-right">
        <span className="text-2xl font-bold">
          {subscription?.subscription.price === 0 ? 'Free' : `$${subscription?.subscription.price}${getBillingIntervalDisplay()}`}
        </span>
      </div>
    </div>
    
    <div className="mb-4">
      <p className="text-sm">
        <span className="font-medium">Status:</span> {getSubscriptionStatus()}
      </p>
      <p className="text-sm">
        <span className="font-medium">Next billing:</span> {getNextBillingDate()}
      </p>
    </div>
    
    <p className="text-sm mb-4">
      {subscription?.subscription.tier === 'BRONZE' && subscription?.subscription.price === 0 
        ? 'Basic features available' 
        : 'Enjoy premium features and priority support'}
    </p>
    
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
      <div>
        <p className="text-sm font-medium mb-1">Keywords</p>
        <div className={`${colors.progressBg} rounded-full h-2`}>
          <div 
            className={`${colors.progressFill} rounded-full h-2`}
            style={{ width: `${(subscription?.usage.keyword_count / subscription?.usage.max_keywords) * 100}%` }}
          />
        </div>
        <p className="text-xs mt-1">{subscription?.usage.keyword_count}/{subscription?.usage.max_keywords}</p>
      </div>
      <div>
        <p className="text-sm font-medium mb-1">Subreddits</p>
        <div className={`${colors.progressBg} rounded-full h-2`}>
          <div 
            className={`${colors.progressFill} rounded-full h-2`}
            style={{ width: `${(subscription?.usage.subreddit_count / subscription?.usage.max_subreddits) * 100}%` }}
          />
        </div>
        <p className="text-xs mt-1">{subscription?.usage.subreddit_count}/{subscription?.usage.max_subreddits}</p>
      </div>
    </div>
    
    <div className="flex justify-between items-center">
      {(subscription?.subscription.tier !== 'BRONZE' || subscription?.subscription.price > 0) ? (
        <button 
          className="flex items-center text-current hover:underline text-sm font-medium"
          onClick={handleManageSubscription}
        >
          Manage Subscription <ArrowRight className="w-4 h-4 ml-1" />
        </button>
      ) : (
        <div></div>
      )}
      {subscription?.subscription.tier !== 'GOLD' && (
        <button 
          className="flex items-center bg-white text-amber-800 px-3 py-1 rounded-full text-sm font-medium hover:bg-amber-50 transition-colors duration-300"
          onClick={handleUpgradeSubscription}
        >
          Upgrade <ArrowUpCircle className="w-4 h-4 ml-1" />
        </button>
      )}
    </div>
  </motion.div>

              <motion.div 
                className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 p-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <h2 className="text-2xl font-semibold mb-4 text-gray-800">Profile Information</h2>
                <div className="space-y-3">
                  <div className="flex justify-between">
                    <span className="font-medium">Email:</span>
                    <span>{user.email}</span>
                  </div>
                </div>
                <button 
                  className="mt-4 flex items-center text-blue-600 hover:text-blue-800 text-sm font-medium"
                >
                  <Edit className="w-4 h-4 mr-1" />
                  Edit Profile
                </button>
              </motion.div>
            </div>
          </motion.main>
        </AnimatePresence>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <h2 className="text-2xl font-bold mb-4">Manage Your Subscription</h2>
          <p className="mb-4">Your {subscription?.subscription.tier} plan is currently {getSubscriptionStatus()}.</p>
          <div className="flex flex-col space-y-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
            >
              Update Payment Method
            </button>
            <button
              className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition duration-300"
              onClick={() => {
                const currentTier = subscription?.subscription.tier;
                const currentCycle = subscription?.subscription.billing_interval?.interval === 'year' ? 'yearly' : 'monthly';
                const planName = currentTier.charAt(0).toUpperCase() + currentTier.slice(1).toLowerCase();
                window.location.href = `http://localhost:3001/pricing?plan=${planName}&cycle=${currentCycle}&upgradedowngrade=true`;
              }}
            >
              Upgrade or Downgrade Plan
            </button>
            <div className="space-y-2">
              <div className="flex items-center text-gray-500 text-sm">
                <span className="mr-1">Cancellation info</span>
                <div className="relative inline-block group">
                  <Info className="w-4 h-4 cursor-help" />
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 w-64 z-10">
                    Your current privileges will remain active until the end of the current billing period. After that, your account will revert to the free tier.
                  </div>
                </div>
              </div>
              {subscription?.subscription.scheduled_change_reason === 'cancel' ? (
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 w-full"
                  onClick={handleRemoveCancellation}
                  disabled={isRemovingCancellation}
                >
                  {isRemovingCancellation ? 'Removing Cancellation...' : 'Remove Scheduled Cancellation'}
                </button>
              ) : (
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300 w-full"
                  onClick={handleCancelSubscription}
                  disabled={isCancelling}
                >
                  {isCancelling ? 'Cancelling...' : 'Cancel Subscription'}
                </button>
              )}
              {cancellationError && (
                <p className="text-red-500 text-sm mt-2">{cancellationError}</p>
              )}
            </div>
          </div>
        </Modal>

        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification(null)}
          />
        )}
      </div>
    </>
  );
};

export default AccountPage;