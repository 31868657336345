import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { User, Lock, MessageCircle } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '../../../components/ThemeProvider';
import supabase from '../../../supabase/supabaseClient';
import { useAuth } from '../../../AuthContext';
import useAuthenticatedApi from '../../../hooks/useAuthenticatedApi';

const testimonials = [
  {
    quote: "This tool has revolutionized how I monitor Reddit. It's a game-changer for my business.",
    username: "MarketingPro",
    subreddit: "digitalmarketing",
    rating: 5
  },
  {
    quote: "The speed and accuracy of the alerts are impressive. I never miss important discussions now.",
    username: "ContentCreator42",
    subreddit: "YouTubers",
    rating: 4
  }
];

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registerError, setRegisterError] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setUser } = useAuth();
  const { colors } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { authFetch, executeRequest } = useAuthenticatedApi();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setRegisterError("Passwords do not match");
      return;
    }

    try {
      // Step 1: Register with Supabase
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (authError) throw authError;

      if (authData.user) {
        // Step 2: Create user entry in your backend
        const response = await executeRequest(async () => {
          return await authFetch('/api/auth/register', {
            method: 'POST',
            body: JSON.stringify({
              email: authData.user.email,
              supabase_uid: authData.user.id,
            }),
          });
        });

        if (response.user) {
          setUser(response.user);
          
          const params = new URLSearchParams(location.search);
          const redirectUrl = params.get('redirect');
          
          if (redirectUrl) {
            navigate(decodeURIComponent(redirectUrl));
          } else {
            navigate('/');
          }
        } else {
          throw new Error('Failed to create user in backend');
        }
      } else {
        throw new Error('Supabase registration successful but no user returned');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setRegisterError('An error occurred during registration. Please try again.');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const NavLink = ({ to, children }) => {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <a
          href={to}
          className="block px-3 py-2 rounded-md text-lg font-bold text-gray-700 hover:text-blue-600 hover:bg-gray-200"
        >
          {children}
        </a>
      </motion.div>
    );
  };

  const renderNav = () => (
    <div className="fixed top-0 left-0 right-0 z-50 py-4 px-4 sm:px-6 lg:px-8">
      <nav className="container mx-auto bg-white bg-opacity-70 backdrop-filter backdrop-blur-sm shadow-lg rounded-lg">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="flex items-center">
              <img className="h-10 w-auto" src="/img/logo.svg" alt="Logo" />
              </Link>
            </div>
            <div className="hidden lg:flex items-center space-x-8">
              <NavLink to="/#features">Features</NavLink>
              <NavLink to="/#subreddits">Subreddits</NavLink>
              <NavLink to="/#pricing">Pricing</NavLink>
              <NavLink to="/#testimonials">Testimonials</NavLink>
            </div>
            <div className="hidden lg:flex items-center space-x-4">
              <motion.a
                href="/login"
                className="px-4 py-2 rounded-md text-lg font-bold text-blue-600 hover:bg-blue-50 transition duration-150 ease-in-out"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Log In
              </motion.a>
              <motion.a
                href="/register"
                className="px-4 py-2 rounded-md text-lg font-bold bg-blue-600 text-white hover:bg-blue-700 transition duration-150 ease-in-out"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Sign Up
              </motion.a>
            </div>
            <div className="lg:hidden">
              <button onClick={toggleMenu} className="text-gray-700 hover:text-blue-600">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-4 pt-2 pb-3 space-y-1">
              <NavLink to="/#features">Features</NavLink>
              <NavLink to="/#subreddits">Subreddits</NavLink>
              <NavLink to="/#pricing">Pricing</NavLink>
              <NavLink to="/#testimonials">Testimonials</NavLink>
              <Link to="/login" className="block px-3 py-2 rounded-md text-lg font-bold text-gray-700 hover:text-blue-600 hover:bg-gray-200">Log In</Link>
              <Link to="/register" className="block px-3 py-2 rounded-md text-lg font-bold text-gray-700 hover:text-blue-600 hover:bg-gray-200">Sign Up</Link>
            </div>
          </div>
        )}
      </nav>
    </div>
  );

  const renderForm = () => (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className={`bg-white ${!isMobile ? 'bg-opacity-70 backdrop-filter backdrop-blur-sm' : ''} rounded-lg shadow-xl p-8 w-full max-w-md relative z-10`}
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Register</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Email</label>
          <div className="relative">
            <User className="absolute left-3 top-3 text-gray-400" size={20} />
            <input
              type="email"
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-50"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Password</label>
          <div className="relative">
            <Lock className="absolute left-3 top-3 text-gray-400" size={20} />
            <input
              type="password"
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-50"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-gray-700">Confirm Password</label>
          <div className="relative">
            <Lock className="absolute left-3 top-3 text-gray-400" size={20} />
            <input
              type="password"
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-50"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-gradient-to-r from-blue-400 to-purple-300 text-white font-bold py-2 px-4 rounded-lg hover:opacity-90 transition duration-300"
        >
          Register
        </button>
      </form>
      {registerError && <p className="text-red-500 text-center mt-4">{registerError}</p>}
      <p className="mt-4 text-center text-gray-600">
        Already have an account?
        <Link
          to={`/login${location.search}`}
          className={`ml-2 ${colors.primaryText} hover:underline`}
        >
          Login
        </Link>
      </p>
    </motion.div>
  );

  const Testimonial = React.memo(({ quote, username, subreddit, rating }) => (
    <div className="bg-gray-50 rounded-lg p-6 shadow-md w-full max-w-md mb-6">
      <div className="flex items-center mb-4">
        <MessageCircle className="w-6 h-6 text-blue-500 mr-2" />
        <div>
          <p className="font-semibold text-gray-900">u/{username}</p>
          <p className="text-gray-500 text-sm">r/{subreddit}</p>
        </div>
      </div>
      <p className="text-gray-600 italic mb-4">"{quote}"</p>
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => (
          <svg
            key={i}
            className={`w-5 h-5 ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
        ))}
      </div>
    </div>
  ));

  return (
    <>
      <Helmet>
        <title>Register - Reddit Alert</title>
        <meta name="description" content="Create your Reddit Alert account to start monitoring Reddit posts and comments with custom keyword alerts." />
        <meta name="keywords" content="register, sign up, Reddit Alert, create account" />
      </Helmet>
      <div className="min-h-screen">
        {renderNav()}

        <div className="flex min-h-screen">
          {/* Testimonials - hidden on mobile */}
          <div className="hidden lg:flex w-1/2 bg-gradient-to-br from-blue-400 to-purple-300 items-center justify-center p-12">
            <div className="flex flex-col items-center justify-center space-y-6 w-full max-w-md">
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index} {...testimonial} />
              ))}
            </div>
          </div>

          {/* Form - centered on mobile, takes up right half on desktop */}
          <div className="w-full lg:w-1/2 flex items-center justify-center relative overflow-hidden p-4 lg:p-12 bg-gradient-to-br from-blue-400 to-purple-300 lg:bg-none">
            <div className="absolute inset-0 z-0 hidden lg:block">
              <div className="absolute top-0 left-0 w-64 h-64 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
              <div className="absolute top-0 right-0 w-72 h-72 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
              <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
            </div>

            <div className="relative z-10 w-full max-w-md">
              {renderForm()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;