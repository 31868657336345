import React from 'react';
import { motion } from 'framer-motion';
import { MessageCircle, Star } from 'lucide-react';

const TestimonialsSection = () => {
  return (
    <div className="py-24 bg-gradient-to-b from-blue-100 to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-4xl font-extrabold text-blue-900 text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Voices from the Reddit Community
        </motion.h2>
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

const TestimonialCard = ({ quote, username, subreddit, rating }) => (
  <motion.div 
    className="bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg rounded-xl shadow-lg p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl"
    whileHover={{ scale: 1.03 }}
  >
    <div className="flex items-center mb-4">
      <div className="bg-blue-500 rounded-full p-2 mr-3">
        <MessageCircle className="w-5 h-5 text-white" />
      </div>
      <div>
        <p className="font-semibold text-blue-900">u/{username}</p>
        <p className="text-blue-600 text-sm">r/{subreddit}</p>
      </div>
    </div>
    <p className="text-blue-800 italic mb-4">"{quote}"</p>
    <div className="flex items-center">
      {[...Array(5)].map((_, i) => (
        <Star 
          key={i} 
          className={`w-5 h-5 ${i < rating ? 'text-yellow-400 fill-current' : 'text-gray-300'}`} 
        />
      ))}
    </div>
  </motion.div>
);

const testimonials = [
  {
    quote: "This tool has revolutionized how I monitor Reddit. It's a game-changer for my business.",
    username: "MarketingPro",
    subreddit: "digitalmarketing",
    rating: 5
  },
  {
    quote: "The speed and accuracy of the alerts are impressive. I never miss important discussions now.",
    username: "ContentCreator42",
    subreddit: "YouTubers",
    rating: 4
  },
  {
    quote: "Excellent customer support and constantly improving features. Highly recommended!",
    username: "SocialMediaGuru",
    subreddit: "socialmedia",
    rating: 5
  },
  {
    quote: "As a moderator, this tool helps me keep track of important topics in my subreddit effortlessly.",
    username: "ModMaster",
    subreddit: "modhelp",
    rating: 5
  },
  {
    quote: "I've tried other Reddit monitoring tools, but this one is by far the best. The UI is intuitive and the alerts are always relevant.",
    username: "TechEnthusiast",
    subreddit: "technology",
    rating: 4
  },
  {
    quote: "This service has helped me stay on top of industry trends. It's an essential tool for any professional.",
    username: "IndustryInsider",
    subreddit: "business",
    rating: 5
  }
];

export default TestimonialsSection;