import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Radar } from 'lucide-react';

const Sonar = () => {
  const [alertDots, setAlertDots] = useState([]);

  useEffect(() => {
    // Generate 4 random positions for the alert dots
    const newDots = Array(4).fill().map(() => ({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
    }));
    setAlertDots(newDots);
  }, []);

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="bg-gradient-to-r from-blue-400 to-purple-300 p-4 rounded-t-lg">
        <div className="flex justify-between items-center">
          <div className="bg-white text-gray-800 px-3 py-1 font-bold rounded-md shadow flex items-center space-x-2">
            <Radar className="w-5 h-5" />
            <span>Alert Scanner</span>
          </div>
          <div className="bg-white text-gray-800 text-xs font-semibold px-2 py-1 rounded-md shadow">
            Alerts Found: <span className="text-green-500">5</span>
          </div>
        </div>
      </div>

      {/* Sonar Card */}
      <div className="relative w-full h-64 sm:h-80 md:h-96 lg:h-[480px] overflow-hidden rounded-b-lg">
        {/* Background Image */}
        <img 
          src="/img/topo.jpg" 
          alt="Topographic map" 
          className="w-full h-full object-cover opacity-25"
        />

        {/* Pulsing Inner Shadow */}
        <motion.div
          className="absolute inset-0 shadow-inner"
          animate={{
            boxShadow: [
              "inset 0 0 20px 10px rgba(0, 0, 0, 0)",
              "inset 0 0 20px 10px rgba(0, 0, 0, 0.45)",
              "inset 0 0 20px 10px rgba(0, 0, 0, 0)",
            ]
          }}
          transition={{
            duration: 3,
            times: [0, 0.1, 0.2],
            repeat: Infinity,
            repeatDelay: 3,
          }}
        />

        {/* Alert Dots */}
        {alertDots.map((dot, index) => (
          <motion.div
            key={index}
            className="absolute w-1.5 h-1.5 bg-red-500 rounded-full"
            style={{ left: dot.left, top: dot.top }}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.7, 1, 0]
            }}
            transition={{
              duration: 3,
              times: [0, 0.7, 1],
              repeat: Infinity,
              repeatDelay: 3,
            }}
          />
        ))}

        {/* Sonar Container */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="relative w-3/4 aspect-square">
            {/* Sonar Circles */}
            {[0, 1, 2].map((index) => (
              <motion.div
                key={index}
                className="absolute inset-0 border-2 border-gray-800 rounded-full"
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 0.4 }}
                transition={{
                  duration: 6,
                  repeat: Infinity,
                  delay: index * 2,
                  ease: "easeOut",
                  times: [0, 0.7, 1]
                }}
              />
            ))}

            {/* Center Dot */}
            <div className="absolute top-1/2 left-1/2 w-2 h-2 bg-gray-800 opacity-45 rounded-full transform -translate-x-1/2 -translate-y-1/2" />

            {/* Rotating Line or Sonar Sweep Line */}
            <motion.div
              className="absolute top-1/2 left-1/2 w-[100%] h-0.5 opacity-45 bg-gray-800 origin-left"
              style={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{
                duration: 6,
                repeat: Infinity,
                ease: "linear"
              }}
            />
          </div>
        </div>

        {/* Overlayed Info */}
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 flex justify-between items-center text-xs font-mono">
          <span>Live Threads: 127</span>
          <span>Alert Freq: 5/min</span>
        </div>
      </div>
    </div>
  );
};

export default Sonar;