export const batchingOptions = ["0m", "15m", "30m", "1h", "2h", "4h", "8h", "12h", "24h"];

export const gradientCombos = [
  { start: "from-purple-200", end: "to-pink-200" },
  { start: "from-blue-200", end: "to-indigo-200" },
  { start: "from-green-200", end: "to-teal-200" },
  { start: "from-red-200", end: "to-orange-200" },
];

export const maxKeywords = 10;
export const maxSubreddits = 5;