import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const Notification = ({ message, type = 'success', duration = 5000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const styles = {
    success: 'bg-green-100 border-green-400 text-green-700',
    error: 'bg-red-100 border-red-400 text-red-700',
    warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
    info: 'bg-blue-100 border-blue-400 text-blue-700'
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: 20, scale: 0.5 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      className={`fixed bottom-5 right-5 ${styles[type]} border-l-4 px-4 py-3 rounded-md shadow-md`}
      role="alert"
    >
      <p>{message}</p>
      <button 
        onClick={onClose}
        className="absolute top-0 right-0 mt-1 mr-1 text-gray-400 hover:text-gray-900"
        aria-label="Close"
      >
        ×
      </button>
    </motion.div>
  );
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  duration: PropTypes.number,
  onClose: PropTypes.func.isRequired
};

export default Notification;