import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import Chip from './Chip';
import { batchingOptions } from './constants';

const KeywordModal = ({ isOpen, onClose, keywordToEdit, confirmAction, subreddit, mode }) => {
  const [localKeyword, setLocalKeyword] = useState({
    keyword: '',
    conditions: { AND: [], NOT: [] },
    enabled: true,
    batch_time: "0",
    notify_email: true,
    notify_push: false
  });
  const [andInput, setAndInput] = useState('');
  const [notInput, setNotInput] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (mode === 'edit' && keywordToEdit) {
        setLocalKeyword({
          ...keywordToEdit,
          batch_time: keywordToEdit.batch_time.toString(),
        });
      } else {
        resetForm();
      }
    }
  }, [isOpen, keywordToEdit, mode]);

  const resetForm = () => {
    setLocalKeyword({
      keyword: '',
      conditions: { AND: [], NOT: [] },
      enabled: true,
      batch_time: "0",
      notify_email: true,
      notify_push: false
    });
    setAndInput('');
    setNotInput('');
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocalKeyword(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleNotificationChange = (type) => {
    setLocalKeyword(prev => ({
      ...prev,
      [`notify_${type}`]: !prev[`notify_${type}`]
    }));
  };

  const handleAddCondition = (type) => {
    const input = type === 'AND' ? andInput : notInput;
    if (input.trim()) {
      setLocalKeyword(prev => ({
        ...prev,
        conditions: {
          ...prev.conditions,
          [type]: [...prev.conditions[type], input.trim()]
        }
      }));
      type === 'AND' ? setAndInput('') : setNotInput('');
    }
  };

  const handleRemoveCondition = (type, index) => {
    setLocalKeyword(prev => ({
      ...prev,
      conditions: {
        ...prev.conditions,
        [type]: prev.conditions[type].filter((_, i) => i !== index)
      }
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedKeyword = {
        ...localKeyword,
        batch_time: localKeyword.batch_time,
        notify_email: localKeyword.notify_email,
        notify_push: localKeyword.notify_push
      };
      await confirmAction(updatedKeyword, subreddit);
      onClose();
      resetForm();
    } catch (error) {
      console.error(`Error ${mode === 'edit' ? 'editing' : 'adding'} keyword:`, error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="space-y-6">
        <h2 className="text-2xl font-bold">{mode === 'edit' ? 'Edit Keyword' : 'Add New Keyword'}</h2>
        
        {/* Keyword input */}
        <div>
          <label htmlFor="keyword" className="block text-sm font-medium text-gray-700">Keyword</label>
          <input
            id="keyword"
            name="keyword"
            type="text"
            value={localKeyword.keyword}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Enter keyword"
          />
        </div>

        {/* AND Conditions */}
        <div>
          <label className="block text-sm font-medium text-gray-700">AND Conditions</label>
          <div className="flex flex-wrap gap-2 mt-1 mb-2">
            {localKeyword.conditions.AND.map((condition, index) => (
              <Chip 
                key={`and-${index}`}
                text={condition} 
                onDelete={() => handleRemoveCondition('AND', index)}
                isAnd={true}
              />
            ))}
          </div>
          <div className="flex mt-1">
            <input
              type="text"
              value={andInput}
              onChange={(e) => setAndInput(e.target.value)}
              className="flex-grow border border-gray-300 rounded-l-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Add AND condition"
            />
            <button
              onClick={() => handleAddCondition('AND')}
              className="bg-indigo-600 text-white px-4 py-2 rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add
            </button>
          </div>
        </div>

        {/* NOT Conditions */}
        <div>
          <label className="block text-sm font-medium text-gray-700">NOT Conditions</label>
          <div className="flex flex-wrap gap-2 mt-1 mb-2">
            {localKeyword.conditions.NOT.map((condition, index) => (
              <Chip 
                key={`not-${index}`}
                text={condition} 
                onDelete={() => handleRemoveCondition('NOT', index)}
                isAnd={false}
              />
            ))}
          </div>
          <div className="flex mt-1">
            <input
              type="text"
              value={notInput}
              onChange={(e) => setNotInput(e.target.value)}
              className="flex-grow border border-gray-300 rounded-l-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Add NOT condition"
            />
            <button
              onClick={() => handleAddCondition('NOT')}
              className="bg-red-600 text-white px-4 py-2 rounded-r-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Add
            </button>
          </div>
        </div>

        {/* Enabled and Batching */}
        <div className="flex items-center justify-between">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="enabled"
              checked={localKeyword.enabled}
              onChange={handleInputChange}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <span className="ml-2 text-sm text-gray-900">Enabled</span>
          </label>
          <div className="flex items-center">
            <span className="mr-2 text-sm text-gray-700">Batching:</span>
            <select
              name="batch_time"
              value={localKeyword.batch_time}
              onChange={handleInputChange}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {batchingOptions.map((option) => (
                <option key={option} value={option}>
                  {option === "0" ? "No batching" : `${option}`}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Notifications */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Notifications</label>
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={() => handleNotificationChange('email')}
              className={`px-4 py-2 rounded-md ${
                localKeyword.notify_email
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-200 text-gray-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Email
            </button>
            <button
              type="button"
              onClick={() => handleNotificationChange('push')}
              className={`px-4 py-2 rounded-md ${
                localKeyword.notify_push
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-200 text-gray-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Push
            </button>
          </div>
        </div>

        {/* Save Button */}
        <button
          onClick={handleSave}
          disabled={isSaving}
          className={`w-full ${
            isSaving ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
          } text-white px-4 py-2 rounded-md transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          {isSaving ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {mode === 'edit' ? 'Saving...' : 'Adding...'}
            </span>
          ) : (
            mode === 'edit' ? 'Save Changes' : 'Add Keyword'
          )}
        </button>
      </div>
    </Modal>
  );
};

export default KeywordModal;