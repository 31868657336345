import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import supabase from './supabase/supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const updateSession = useCallback((newSession) => {
        setSession(newSession);
        setUser(newSession?.user ?? null);
    }, []);

    useEffect(() => {
        let mounted = true;

        const fetchSession = async () => {
            try {
                console.log('Fetching session...');
                const { data, error } = await supabase.auth.getSession();
                if (error) throw error;
                if (mounted) {
                    console.log('Session fetched:', data.session);
                    updateSession(data.session);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching session:', error);
                if (mounted) {
                    setError(error.message);
                    setLoading(false);
                }
            }
        };

        fetchSession();

        const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
            if (mounted) {
                updateSession(session);
                setLoading(false);
            }
        });

        return () => {
            mounted = false;
            listener.subscription.unsubscribe();
        };
    }, [updateSession]);

    const login = useCallback(async (email, password) => {
        try {
            const { data, error } = await supabase.auth.signInWithPassword({ 
                email, 
                password,
                options: {
                    persistSession: true
                }
            });
            if (error) throw error;
            updateSession(data.session);
        } catch (error) {
            setError(error.message);
            throw error;
        }
    }, [updateSession]);

    const logout = useCallback(async () => {
        try {
            await supabase.auth.signOut();
            updateSession(null);
        } catch (error) {
            setError(error.message);
            throw error;
        }
    }, [updateSession]);

    const refreshToken = useCallback(async () => {
        try {
            const { data, error } = await supabase.auth.refreshSession();
            if (error) throw error;
            updateSession(data.session);
            return data.session.access_token;
        } catch (error) {
            console.error('Error refreshing token:', error);
            setError(error.message);
            return null;
        }
    }, [updateSession]);

    const value = useMemo(() => ({
        user,
        session,
        loading,
        error,
        login,
        logout,
        refreshToken,
        getAccessToken: () => session?.access_token,
    }), [user, session, loading, error, login, logout, refreshToken]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};